<div class="container" *ngIf="projectAuthorities$ | async as authorities">
  <p class="subtitle-1">{{ 'PUBLIC.DETAIL.AUTHORITY_SECTION.TITLE' | translate }}</p>
  <div class="card" *ngFor="let authority of authorities">
    <div class="header">
      <p class="subtitle-2">{{'PUBLIC.DETAIL.AUTHORITY_SECTION.SUB_TITLE' | translate }}</p>
      <p class="subtitle-2 name">{{authority.name}}</p>
    </div>
    <div class="contact-info" hidden>
      <div class="details" *ngIf="authority?.address">
        <svg-icon key="map" size="sm"></svg-icon>
        <caption>
          {{ authority?.address }}
        </caption>
      </div>

      <div class="details" *ngIf="authority?.email">
        <svg-icon key="email" size="sm"></svg-icon>
        <caption>
          {{authority?.email}}
        </caption>
      </div>

      <div class="details" *ngIf="authority?.phone">
        <svg-icon key="phone" size="sm"></svg-icon>
        <caption>
          {{authority?.phone}}
        </caption>
      </div>
    </div>
  </div>
</div>
