import { map, Subject, takeUntil } from 'rxjs';

import { inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '@ggp/generic/shared/services';

const DataType: { [key: string]: string } = {
  activities: 'activities',
  classes: 'class',
  execution_site: 'region',
};

@Pipe({
  name: 'translateTrees',
  standalone: true,
})
export class TranslateTreesPipe implements PipeTransform, OnDestroy {
  #filterService = inject(FilterService);
  readonly #onDestroy = new Subject();

  transform(key: string, currentLang: string, parentKey: string) {
    const dataType = DataType[parentKey];
    if (!dataType) {
      throw new Error(`Invalid parentKey: ${parentKey}`);
    }

    return this.getTranslationData(dataType, currentLang, key).pipe(takeUntil(this.#onDestroy));
  }

  private getTranslationData(dataType: string, currentLang: string, selectedKey: string) {
    return this.#filterService.getTreeData(dataType, currentLang).pipe(
      takeUntil(this.#onDestroy),
      map(data => {
        const foundTitle = this.searchInChildren(data, selectedKey);
        return foundTitle ? foundTitle : '';
      }),
    );
  }

  searchInChildren(children: any[], selectedKey: string): string {
    for (const child of children) {
      if (child.key === selectedKey) {
        return child.title;
      }
      if (child.children && child.children.length > 0) {
        const foundInChild = this.searchInChildren(child.children, selectedKey);
        if (foundInChild) {
          return foundInChild;
        }
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    this.#onDestroy.next(null);
    this.#onDestroy.complete();
  }
}
