import { gql } from '@apollo/client';

export const getProductsQueryGQL = gql`
  query getProductsQuery {
    getProductsQuery{
      product_id
      id
      subscription_id
      value
    }
  }
`;

export const getSubscriptionPageQueryGQL = gql`
  query getSubscriptionPageQuery($scope: String!, $locale: Language!, $pageType: PageType!) {
    getSubscriptionPageQuery(scope: $scope, locale: $locale, pageType: $pageType){
      title
      card_content
      content
      submit_btn_title
      card_note
      card_title
      link
      page_type
      card_title_2
      options_2{
          id
          description
      }
      options{
          id
          description
      }
    }
  }
`;

export const getPriorSourceQueryGQL = gql`
  query getPriorSourceQuery($projectId: String!) {
    getPriorSourceQuery(projectId: $projectId){
      stage
      source
    }
  }
`;
