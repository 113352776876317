import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '@ggp/generic/shared/components/snackbar';

type SnackbarType = 'success' | 'info' | 'danger' | 'warning';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private readonly snackBar = inject(MatSnackBar);

  openToaster(message: string, type: SnackbarType = 'danger', title?: string, duration?: number) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {
        title,
        message,
        type,
      },
      panelClass: `app-notification-${type}`,
      duration: duration || 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
