import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ProjectLink } from '@ggp/generic/shared/util/models';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ggp-link',
  standalone: true,
  imports: [TranslateModule, SvgIconComponent, MatButtonModule, MatMenuModule, TitleCasePipe, NgIf, NgFor],
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Input() originalLinks: ProjectLink[] = [];

  readonly otherLabel = 'OTHER';
  readonly tedLink = 'TED';
  readonly threePLink = '3P';
  readonly otherLink = 'PUBLIC.DETAIL.OTHER';
}
