<div class="container" *ngIf="projectGeneralInfo | async as selectedProject">
  <h6 class="stage-name">{{ currentStage | translate }}</h6>
  <span class="publication-date body-2">
    <span class="last-update" *ngIf="!(selectedProject.lastModificationDate | compareDate: selectedProject.creationDate)">
      {{ 'CONSTRUCTION.DETAIL.UPDATE_BOX' | translate }}: {{ selectedProject.lastModificationDate | localizedDate }}
    </span>
    <span>{{ 'CONSTRUCTION.DETAIL.PUBLISHED' | translate }}: {{ selectedProject.creationDate | localizedDate }}</span>
  </span>
  <h4 class="title" [innerHTML]="selectedProject.title"></h4>
  <div class="detail-box" [style.grid-template-columns]="'repeat(' + columns.length + ', minmax(0, 1fr))'">
    <div *ngIf="isColumnVisible('authority')" class="authority" #section>
      <p class="body-2">{{ 'CONSTRUCTION.DETAIL.AUTHORITY' | translate }}:</p>
      <p id="authority-text" style="line-height: 24px;" class="subtitle-1 authority-p">
        <span *ngFor="let name of selectedProject.companyNames" class="separator">
          {{ name }}
        </span>
      </p>
      <p class="subtitle-1 action">
        <span (click)="handleAction('authority','show')">{{ 'PUBLIC.DETAIL.SHOW_MORE' | translate }}</span>
        <span (click)="handleAction('authority','hide')">{{ 'PUBLIC.DETAIL.HIDE' | translate }}</span>
      </p>
    </div>

    <div *ngIf="isColumnVisible('region')" class="region" #section>
      <p class="body-2">{{ 'CONSTRUCTION.DETAIL.REGION' | translate }}:</p>
      <p id="region-text" style="line-height: 24px;" class="subtitle-1 region-p">
        <span *ngFor="let region of getRegions(selectedProject.regions)" class="separator">
          {{ region }}
        </span>
      </p>
      <p class="subtitle-1 action">
        <span (click)="handleAction('region','show')">{{ 'PUBLIC.DETAIL.SHOW_MORE' | translate }}</span>
        <span (click)="handleAction('region','hide')">{{ 'PUBLIC.DETAIL.HIDE' | translate }}</span>
      </p>
    </div>

    <div *ngIf="isColumnVisible('construction-cost')" class="construction-cost">
      <p class="body-2">{{ 'CONSTRUCTION.DETAIL.COST' | translate }}:</p>
      <p class="subtitle-1">
        {{
          selectedProject.estimatedValue.minValue !== null && selectedProject.estimatedValue.maxValue !== null ?
            formatCost(selectedProject.estimatedValue.minValue) + ' - ' + formatCost(selectedProject.estimatedValue.maxValue) :
            (selectedProject.estimatedValue.minValue !== null ? formatCost(selectedProject.estimatedValue.minValue) :
              (selectedProject.estimatedValue.maxValue !== null ? formatCost(selectedProject.estimatedValue.maxValue) : ''))
        }}
      </p>
      <span *ngIf="selectedProject.estimatedValue.minValue !== null || selectedProject.estimatedValue.maxValue !== null">
        {{ 'CONSTRUCTION.DETAIL.ESTIMATED' | translate }}
      </span>
    </div>

    <div *ngIf="isColumnVisible('surface')" class="surface" #section>
      <p class="body-2">{{ 'CONSTRUCTION.DETAIL.SURFACE' | translate }}:</p>
      <p style="line-height: 24px;" class="subtitle-1 surface-p">
        <ng-container *ngFor="let item of selectedProject.surfaces">
          <ng-container *ngIf="item.value !== null">
            <span class="body-2">{{ item.value }}:</span>
          </ng-container>
          <ng-container *ngIf="item.minValue !== null && item.maxValue !== null"> {{ item.minValue }} - {{ item.maxValue }} m2 </ng-container>
          <ng-container *ngIf="item.minValue !== null && item.maxValue === null"> {{ item.minValue }} m2</ng-container>
          <ng-container *ngIf="item.minValue === null && item.maxValue !== null"> {{ item.maxValue }} m2</ng-container>
        </ng-container>
      </p>
      <p class="subtitle-1 action">
        <span (click)="handleAction('surface','show')">{{ 'PUBLIC.DETAIL.SHOW_MORE' | translate }}</span>
        <span (click)="handleAction('surface','hide')">{{ 'PUBLIC.DETAIL.HIDE' | translate }}</span>
      </p>
    </div>

    <div *ngIf="isColumnVisible('construction-start')" class="construction-start">
      <p class="body-2">{{ 'CONSTRUCTION.DETAIL.START_DATE' | translate }}:</p>
      <p class="subtitle-1">{{ selectedProject.startDate | localizedDate }}</p>
    </div>
  </div>
  <div class="tags">
    <mat-chip-set #chipGrid>
      <mat-chip-row *ngFor="let label of selectedProject.tags" (removed)="remove(selectedProject.id, label)" [style]="getLabelBorder(label.color)">
        <span class="subtitle-2">{{ label.label }}</span>
        <button matChipRemove [attr.aria-label]="'remove ' + label.label">
          <svg-icon key="close" size="xs"></svg-icon>
        </button>
      </mat-chip-row>
    </mat-chip-set>
  </div>
</div>
