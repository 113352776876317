<ng-container *ngIf="(renewalDates | async) as renewalDates">
  <div *ngIf="displayDates(renewalDates)" class="container">
    <div *ngIf="renewalDates.startDate" class="element-container">
      <span class="subtitle-2">{{ 'PUBLIC.DETAIL.RENEWAL_DATES.START_DATE' | translate }}:</span>
      <span class="body-2"> {{ renewalDates.startDate | localizedDate }} </span>
    </div>
    <div *ngIf="renewalDates.firstPossibleEndDate" class="element-container">
      <span class="subtitle-2">{{ 'PUBLIC.DETAIL.RENEWAL_DATES.FIRST_POSSIBLE_END_DATE' | translate }}:</span>
      <span class="body-2">{{ renewalDates.firstPossibleEndDate | localizedDate }} </span>
    </div>
    <div *ngIf="renewalDates.lastPossibleEndDate" class="element-container">
      <span class="subtitle-2">{{ 'PUBLIC.DETAIL.RENEWAL_DATES.LAST_POSSIBLE_END_DATE' | translate }}:</span>
      <span class="body-2"> {{ renewalDates.lastPossibleEndDate | localizedDate }} </span>
    </div>
  </div>
</ng-container>
