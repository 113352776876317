import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { AppConfig } from '@ggp/generic/shared/config/app';

export class AppTranslateLoader implements TranslateLoader {
  readonly #httpClient = inject(HttpClient);

  constructor(private environment: AppConfig) {}

  getTranslation(lang: string) {
    return this.#httpClient.get(`assets/i18n/${lang}.json?version=${this.environment.version}`);
  }
}
