// @ts-nocheck
/* eslint-disable */
import { ggpIconAttachmentIcon } from './attachment';
import { ggpIconCalendarIcon } from './calendar';
import { ggpIconEuroIcon } from './euro';
import { ggpIconFilterByIcon } from './filter-by';
import { ggpIconFilterIcon } from './filter';
import { ggpIconIncludeIcon } from './include';
import { ggpIconMinusCircleIcon } from './minus-circle';
import { ggpIconNoDataIcon } from './no-data';
import { ggpIconPlusCircleIcon } from './plus-circle';
import { ggpIconPlusIcon } from './plus';
import { ggpIconReadEmailIcon } from './read-email';
import { ggpIconUnreadEmailIcon } from './unread-email';
export const filtersIcons = [ggpIconAttachmentIcon, ggpIconCalendarIcon, ggpIconEuroIcon, ggpIconFilterByIcon, ggpIconFilterIcon, ggpIconIncludeIcon, ggpIconMinusCircleIcon, ggpIconNoDataIcon, ggpIconPlusCircleIcon, ggpIconPlusIcon, ggpIconReadEmailIcon, ggpIconUnreadEmailIcon];
