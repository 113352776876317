import { map, Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ProjectRectification } from '@ggp/ebp/leads/util';
import { Accordion, AccordionComponent } from '@ggp/generic/shared/components/accordion';
import { LocalizedDatePipe } from '@ggp/generic/shared/pipes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ggp-rectifications',
  standalone: true,
  imports: [CommonModule, AccordionComponent, TranslateModule],
  providers: [LocalizedDatePipe],
  templateUrl: './rectifications.component.html',
  styleUrls: ['./rectifications.component.scss'],
})
export class RectificationsComponent implements OnInit {
  private readonly localizedDatePipe = inject(LocalizedDatePipe);
  private readonly translateService = inject(TranslateService);

  @Input({ required: true }) rectifications!: Observable<ProjectRectification[]> | undefined;
  menu$!: Observable<Accordion[]> | undefined;
  accordionMenu: Accordion[] = [];

  ngOnInit(): void {
    this.menu$ = this.rectifications?.pipe(
      map((updates: ProjectRectification[]) => {
        updates.map(update => {
          this.accordionMenu = [
            ...this.accordionMenu,
            {
              title: `${this.translateService.instant('PUBLIC.DETAIL.RECTIFICATIONS.RECTIFICATION')} - ${this.localizedDatePipe.transform(
                new Date(update.publicationDate),
              )}`,
              amount: null,
              award: null,
              update: { date: update.publicationDate, content: update.text },
            },
          ];
        });
        return this.accordionMenu.sort((a, b) => {
          return new Date(b.update?.date as string).getTime() - new Date(a.update?.date as string).getTime();
        });
      }),
    );
  }
}
