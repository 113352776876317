import { from, Observable, switchMap } from 'rxjs';

import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { fetchAuthSession } from 'aws-amplify/auth';


export function AuthInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  return from(fetchAuthSession()).pipe(
    switchMap(session => {
      const idToken = session.tokens?.idToken?.toString();
      const request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${idToken}`),
      });

      return next(request);
    }))

}
