import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Organisation, OrganisationFilters, OrganisationSortCol, Sort } from '@ggp/generic/shared/util/models';

import { CompanyServiceInterface } from './models';

@Injectable({
  providedIn: 'root',
})
export class CompanyService implements CompanyServiceInterface {
  getCompanies(pageNumber: number, sort?: Sort<OrganisationSortCol>, filters?: OrganisationFilters): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getCompanyById(organisationId: string): Observable<Organisation> {
    throw new Error('Method not implemented.');
  }
}
