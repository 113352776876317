<div class="top-bar">
  <div class="brand-info" [class.backoffice]="backoffice.isBackOffice">
    <div class="logo">
      <img ngSrc="assets/img/logo.svg" width="80" height="60" priority alt="Logo" [routerLink]="defaultRoute" data-cy="ggp-topbar-logo" />
    </div>

    <div *ngIf="backoffice.isBackOffice" class="backoffice-logo">
      <span class="subtitle-1">Backoffice</span>
    </div>

    <div *ngIf="impersonatedUser$ | async as impersonatedUser" class="masquerading-logo">
      <span class="subtitle-1">{{'MASQUERADING.TITLE' | translate}}</span>
      <span class="caption">{{impersonatedUser.first_name | titlecase}} {{impersonatedUser.last_name| titlecase}}</span>
    </div>
  </div>

  <div class="buttons">
    <ggp-language-switcher [plateform]="plateform" class="switch-lang" [disabled]="backoffice.isBackOffice"></ggp-language-switcher>

    <ggp-notification *ngIf="showNotifications" class="notification-bell"></ggp-notification>

    <!-- profile button -->
    <button mat-button class="top-icons" [matMenuTriggerFor]="menu" data-test-id="ggp-topbar-user" data-cy="user">
      <mat-icon fontIcon="person_outline"></mat-icon>
    </button>

    <mat-menu #menu yPosition="below" class="language-menu">
      <ng-container *ngFor="let item of menuItems">
        <button mat-menu-item [value]="item.value" (click)="onItemClick(item.value)" [attr.data-cy]="item.value">
          <ng-container>
            <p class="body-2" [attr.data-test-id]="item.value">{{ item.label | translate }}</p>
          </ng-container>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>
