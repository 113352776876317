import mongoose from 'mongoose';

const schema = new mongoose.Schema({
  userId: {
    type: String,
    required: true,
  },
  organisationId: {
    type: String,
    required: true,
  },
  favorite: {
    type: Boolean,
    default: false,
  }
});

schema.index({ userId: 1, organisationId: 1 }, { unique: true });

export const OrganisationUserDataModel = mongoose.model('OrganisationUserData', schema);
