import { saveAs } from 'file-saver';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly httpClient = inject(HttpClient);

  downloadFile(url: string, name: string) {
    return this.httpClient.get(url, { responseType: 'blob' }).subscribe(data => saveAs(data, name));
  }

  getFilePathFromBinary(binaryData: string, contentType: string) {
    binaryData = window.atob(binaryData);
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i = i + 1) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: contentType });
    return URL.createObjectURL(blob);
  }

  downloadPdfFromBase64(data: string, fileName: string) {
    data = 'data:application/pdf;base64,' + data;
    const downloadLink = document.createElement('a');
    downloadLink.href = data;
    downloadLink.download = fileName + '.pdf';
    downloadLink.click();
    downloadLink.remove();
  }

  downloadPdfFromBinary(data: string, fileName: string, contentType: string) {
    const url = this.getFilePathFromBinary(data, contentType);
    this.downloadFile(url, fileName);
  }

  download(presignedUrl: string, fileName?: string) {
    if (presignedUrl) {
      if (fileName) {
        saveAs(presignedUrl, fileName?.replace(/\./g, ''));
      } else {
        const link = document.createElement('a');
        link.href = presignedUrl;
        link.download = presignedUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
