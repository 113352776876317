import { gql } from 'apollo-angular';

export const getFilesQueryGQL = gql`
  query getFilesQuery($projectId: String!){
    getFilesQuery(projectId: $projectId){
      files {
        createdAt
        extension
        category
        id
        mime
        name
        path
        preview
        size
        updatedAt
       }
       documentId
    }
  }
`;

export const getFileContentQueryGQL = gql`
  query getFileContentQuery($fileId: String!, $documentId: String!){
    getFileContentQuery(fileId: $fileId, documentId: $documentId)
  }
`;

export const searchByKeywordsDocumentsQueryGQL = gql`
query searchByKeywordsDocumentsQuery($projectId: String!, $keywords: [String]!, $language: Language!) {
  searchByKeywordsDocumentsQuery(projectId: $projectId, keywords: $keywords, language: $language){
    id
    mime
    path
    name
    extension
    preview
    text
  }
}
`;

export const downloadFileQueryGQL = gql`
  query downloadFileQuery($documentId: String!, $filesId: [String]!){
    downloadFileQuery(documentId: $documentId, filesId: $filesId){
      url
      progress
      status
    }
  }
`;
