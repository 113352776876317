export enum ProductsTypes {
  PRIOR_NOTICE = 'PRIOR_NOTICE',
  PRIOR_NOTICE_AC = 'PRIOR_NOTICE_AC',
  PRIOR_NOTICE_DC = 'PRIOR_NOTICE_DC',
  OPENING = 'OPENING',
  AWARD = 'AWARD',
  RENEWAL = 'RENEWAL',
  PUBLIC_TENDER = 'PUBLIC_TENDER',
  PRIVATE_PROJECT = 'PRIVATE_PROJECT',
  DCE_KEYWORDS = 'DCE_KEYWORDS',
  COMPANIES = 'COMPANIES',
}
