import { marked } from 'marked';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'marked',
  standalone: true,
})
export class MarkedPipe implements PipeTransform {
  transform(text: string) {
    return text ? marked.parse(text) : null;
  }
}
