import { BehaviorSubject, first, map, Observable, Subject, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { PUBLIC_STAGES_TOKEN } from '@ggp/generic/shared/config/token';
import { DataLoadingDirective } from '@ggp/generic/shared/directives/data-loading';
import { LocalizedDatePipe } from '@ggp/generic/shared/pipes';
import {
  FilterService,
  Notification,
  NotificationService,
  NotificationType,
  ProjectType,
  SubscriptionService,
  ToasterService,
} from '@ggp/generic/shared/services';
import { CONTRUCTION_STAGE_LIST } from '@ggp/generic/shared/util/models';
import { SvgIconComponent, SvgIcons } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ggp-notification',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    TranslateModule,
    MatSlideToggleModule,
    DataLoadingDirective,
    MatChipsModule,
  ],
  providers: [LocalizedDatePipe],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit, OnDestroy {
  readonly #notificationService = inject(NotificationService);
  readonly #subscriptionService = inject(SubscriptionService);
  readonly #router = inject(Router);
  readonly #toasterService = inject(ToasterService);
  readonly #translate = inject(TranslateService);
  readonly #cd = inject(ChangeDetectorRef);
  readonly #onDestroy = new Subject();
  readonly #filterService = inject(FilterService);
  readonly #localizedDatePipe = inject(LocalizedDatePipe);
  readonly #publicStageList = inject(PUBLIC_STAGES_TOKEN);

  #notifications!: any[];

  dataIsLoading$ = new BehaviorSubject<boolean>(false);
  notificationCpt$!: Observable<number>;

  today!: Notification[];
  yesterday!: Notification[];
  older!: Notification[];

  unreadNotifications = false;
  maxCountToDisplay = 9;

  ngOnInit(): void {
    this.notificationCpt$ = this.#notificationService.getAppNotificationsCount().pipe(first());
  }

  menuOpened(): void {
    this.dataIsLoading$.next(true);

    this.#notificationService
      .getAppNotifications()
      .pipe(takeUntil(this.#onDestroy))
      .subscribe({
        next: (data: any) => {
          this.dataIsLoading$.next(false);
          this.#notifications = data;

          this.today = this.getNotificationsMaped().today ?? [];
          this.yesterday = this.getNotificationsMaped().yesterday ?? [];
          this.older = this.getNotificationsMaped().older ?? [];
        },
        error: ({ error }) => {
          this.dataIsLoading$.next(false);
          this.#toasterService.openToaster(this.#translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.#translate.instant('ERRORS.SERVER.TITLE'));
        },
      });
  }

  private getNotificationsMaped() {
    return this.#notificationService.mapNotifications(this.#notifications);
  }

  getList(list: Notification[]) {
    if (this.unreadNotifications) {
      return list.filter(n => !n.seen);
    }
    return list;
  }

  redirectUser(notification: any, period: string, profileId?: string) {
    this.#filterService.clearAll();

    if (!notification.seen) this.markNotificationAsRead(notification, period);

    if (notification.notificationType === NotificationType.LEAD_SHARE && notification.content[0]?.url) {
      this.updateQueryParamsAndNavigate(notification.content[0]?.projectType, notification.content[0]?.url);
      return;
    }

    const { url, projectType } =
      notification.notificationType === NotificationType.NEW_PUBLIC_TENDERS
        ? { url: '/leads/public-projects', projectType: 'PUBLIC_TENDER' }
        : { url: '/leads/construction-projects', projectType: 'PRIVATE_PROJECT' };

    this.filterByLastUpdate(notification);
    this.handleNavigateNotification(url, {
      folder: 'search_profiles',
      projectType,
      subFolderId: profileId,
    });
  }

  markAllAsRead() {
    this.markNotificationAsRead({} as Notification, 'all');
  }

  markNotificationAsRead(notification: Notification, period: string) {
    const notificationType = notification ? notification.notificationType : null;
    const notificationDateByType = notificationType === NotificationType.LEAD_SHARE ? notification : null;

    this.#notificationService
      .markNotificationAsRead(notificationType, this.getDates(period, notificationDateByType).dateFrom, this.getDates(period, notificationDateByType).dateTo)
      .pipe(
        first(),
        map(n => {
          return { ...notification, seen: true };
        }),
        takeUntil(this.#onDestroy),
      )
      .subscribe({
        next: data => {
          if (notificationType) this.readNotification(notification, data, period);
          if (!notificationType) {
            this.today = this.markNotificationsAsSeen(this.today);
            this.yesterday = this.markNotificationsAsSeen(this.yesterday);
            this.older = this.markNotificationsAsSeen(this.older);
          }
          this.ngOnInit();
          this.#cd.detectChanges();
        },
        error: error => {
          this.#toasterService.openToaster(this.#translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.#translate.instant('ERRORS.SERVER.TITLE'));
        },
      });
  }

  markNotificationsAsSeen(notifications: Notification[]): Notification[] {
    return notifications.map(notification => ({
      ...notification,
      seen: true,
    }));
  }

  private readNotification(notification: Notification, data: Notification, period: string) {
    let index = 0;
    switch (period) {
      case 'today':
        index = this.findNotificationIndex(this.today, notification);
        this.today[index] = data;
        break;
      case 'yesterday':
        index = this.findNotificationIndex(this.yesterday, notification);
        this.yesterday[index] = data;
        break;
      case 'older':
        index = this.findNotificationIndex(this.older, notification);
        this.older[index] = data;
        break;
    }
  }

  private findNotificationIndex(notifications: Notification[], notification: Notification): number {
    return notifications.findIndex(
      n =>
        n.notificationDate === notification.notificationDate &&
        n.notificationType === notification.notificationType &&
        n.content.some(content => content.projectId === notification.content[0].projectId),
    );
  }

  getUnreadCounter(list: Notification[]) {
    return list?.filter(n => !n.seen).length;
  }

  private getDates(period: string, notification: Notification | null) {
    if (notification) return { dateFrom: notification.notificationDate, dateTo: notification.notificationDate };

    const today = new Date();
    const yesterdayDate = new Date(today.getDate() - 1);
    const older = new Date(today.getDate() - 30);
    const tomorrowDate = new Date(today.getDate() + 1);

    switch (period) {
      case 'today':
        return {
          dateFrom: this.getDateFormated(today),
          dateTo: this.getDateFormated(tomorrowDate),
        };

      case 'yesterday':
        return {
          dateFrom: this.getDateFormated(yesterdayDate),
          dateTo: this.getDateFormated(today),
        };

      case 'older':
        return {
          dateFrom: this.getDateFormated(older),
          dateTo: this.getDateFormated(yesterdayDate),
        };

      default:
        return {
          dateFrom: null,
          dateTo: null,
        };
    }
  }

  getIcon(notification: Notification): SvgIcons {
    const allStageIcons = [...this.#publicStageList, ...CONTRUCTION_STAGE_LIST].map(s => s.key.toLocaleLowerCase());

    if (notification.content[0]?.projectStage === 'PRIVATE_CANCELLED') {
      return 'cancel' as SvgIcons;
    }

    const icon = allStageIcons.find(stage => notification.content[0]?.projectStage?.toLocaleLowerCase().includes(stage));

    return icon as SvgIcons;
  }

  private getDateFormated(date: Date): string {
    return date.toISOString();
  }

  private updateQueryParamsAndNavigate(projectType: ProjectType, url: string) {
    let baseUrl = url.split('?')[0];

    const notificationQueryParams = new URLSearchParams(url.split('?')[1]);
    const newQueryParams = new URLSearchParams(this.#router.url.split('?')[1]);

    if (newQueryParams.get('folder') === 'my_searches' || newQueryParams.get('folder') === 'search_profiles') {
      newQueryParams.delete('folder');
      newQueryParams.delete('subFolderId');
    }

    notificationQueryParams.forEach((value, key) => {
      if (value && !newQueryParams.has(value)) newQueryParams.set(key, value);
    });

    if (projectType === ProjectType.PRIVATE_PROJECT) {
      baseUrl += '/construction-projects';
      newQueryParams.set('projectType', 'PRIVATE_PROJECT');
    } else if (projectType === ProjectType.PUBLIC_TENDER) {
      baseUrl += '/public-projects';
      newQueryParams.set('projectType', 'PUBLIC_TENDER');
    }

    this.#router.navigateByUrl(`${baseUrl}?${newQueryParams}`);
  }

  private filterByLastUpdate(notifications: Notification): void {
    const minDate = new Date(notifications.notificationDate);
    const maxDate = new Date(notifications.notificationDate);
    maxDate.setHours(23, 59, 59, 999);

    this.#filterService.updateFilter({
      parentKey: 'last_update',
      displayText: this.selectedDateToText(notifications.notificationDate),
      value: {
        minValue: minDate ?? undefined,
        maxValue: maxDate ?? undefined,
        type: 'FIRST_TIME_PUBLICATIONS_UPDATES',
      },
    });
  }

  private selectedDateToText(date: string): string {
    const minDate = this.#localizedDatePipe.transform(date);
    const maxDate = this.#localizedDatePipe.transform(date);

    return this.#translate.instant('FILTER.LAST_UPDATE_FILTER', { minDate, maxDate });
  }

  private handleNavigateNotification(
    url: string,
    queryParams: {
      folder: string;
      projectType: string;
      subFolderId?: string;
    },
  ): void {
    const currentUrl = this.#router.url;
    const targetUrl = this.#router.createUrlTree([url], { queryParams }).toString();

    currentUrl !== targetUrl ? this.#router.navigate([url], { queryParams }) : this.#filterService.notificationsFiltersSubject = true
  }

  ngOnDestroy(): void {
    this.#onDestroy.next(null);
    this.#onDestroy.complete();
  }
}
