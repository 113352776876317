import { gql } from 'apollo-angular';

export const shareProjectMutationGQL = gql`
  mutation shareProjectMutation($shareProjectInput: ShareProjectInput!) {
    shareProjectMutation(shareProjectInput: $shareProjectInput) {
        success
        count
    }
  }
`;
