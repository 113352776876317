import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionInvoiceStatus } from '@ggp/generic/shared/services';

import { AuthenticationService } from '../services/authentication.service';

export const CommonGuard = () => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  const subscriptionStatus = localStorage.getItem('subscriptionStatus');

  if (!authenticationService.cognitoUser || subscriptionStatus === SubscriptionInvoiceStatus.BLOCKED_UNPAID) return true;

  router.navigateByUrl(router.url);
  return false;
};
