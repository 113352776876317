import { saveAs } from 'file-saver';
/* eslint-disable @angular-eslint/no-input-rename */
import { FindOptions, FindResultMatchesCount, NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { BehaviorSubject, delay, EMPTY, of, switchMap } from 'rxjs';

/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnDestroy, SecurityContext, signal, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GGP_DEFAULT_I18N } from '@ggp/generic/i18n';
import { UpgradeSubscriptionComponent } from '@ggp/generic/shared/components/upgrade-subscription';
import { PLATFORM_TOKEN } from '@ggp/generic/shared/config/token';
import { DataLoadingDirective } from '@ggp/generic/shared/directives/data-loading';
import { HighlighScroll } from '@ggp/generic/shared/services';
import { ProductsTypes } from '@ggp/generic/shared/util/models';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateService } from '@ngx-translate/core';

import { NoPreviewComponent } from '../no-preview/no-preview.component';

@Component({
  selector: 'ggp-pdf',
  standalone: true,
  imports: [CommonModule, NoPreviewComponent, UpgradeSubscriptionComponent, NgxExtendedPdfViewerModule, DataLoadingDirective, SvgIconComponent],
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfComponent implements OnDestroy, OnChanges {
  readonly #sanitizer = inject(DomSanitizer);
  readonly #translateService = inject(TranslateService);
  readonly #ngxExtendedPdfViewerService = inject(NgxExtendedPdfViewerService);
  readonly #plateform = inject(PLATFORM_TOKEN);
  readonly #originalConsoleLog = console.log;
  readonly #originalConsoleWarn = console.warn;
  readonly currentLocale = GGP_DEFAULT_I18N[this.#plateform].languages[this.#translateService.currentLang].local;

  @Input() path?: string | null;
  @Input() hasWithkeywordsProduct = signal<boolean | null>(null);
  @Input({
    required: true,
    transform: (name: string) => {
      //Removes extra dots to display correct extension
      return name ? name.replace(/\./g, '') : name;
    },
  })
  fileName!: string;

  @Input() highlightScroll: HighlighScroll | null = null;
  findOptions: FindOptions = {
    highlightAll: false,
    matchCase: false,
    wholeWords: true,
    matchDiacritics: false,
  };

  pdfIsLoading = new BehaviorSubject(true);
  #totalFinded = new BehaviorSubject<number>(0);

  constructor() {
    this.#suppressLogs();

    this.pdfIsLoading
      .pipe(switchMap(isLoading => (!isLoading && this.highlightScroll ? this.scrollToHighlightedResult(this.highlightScroll) : EMPTY)))
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['highlightScroll']?.currentValue) {
      const newSearchText = changes['highlightScroll'].currentValue;
      this.scrollToHighlightedResult(newSearchText).subscribe();
    }
  }

  subscriptionData = {
    pageType: ProductsTypes.DCE_KEYWORDS,
  };

  getPdfUrl() {
    if (this.path) {
      const sanitizedPath = this.#sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.#sanitizer.bypassSecurityTrustResourceUrl(this.path));
      return sanitizedPath ? sanitizedPath : '';
    }
    return '';
  }

  #suppressLogs() {
    console.log = () => {};
    console.warn = () => {};
  }

  #restoreLogs() {
    console.log = this.#originalConsoleLog;
    console.warn = this.#originalConsoleWarn;
  }

  downloadPdf() {
    saveAs(this.getPdfUrl(), this.fileName);
  }

  pdfLoaded(event: any) {
    this.pdfIsLoading.next(false);
  }

  private scrollToHighlightedResult(search: HighlighScroll) {
    if (!search || this.pdfIsLoading.value) return EMPTY;

    this.resetFindFunction();

    return of(this.#ngxExtendedPdfViewerService.find(search.highlightedSpan, this.findOptions)).pipe(
      delay(500),
      switchMap(found => {
        if (found && search.index > 1 && search.index <= this.#totalFinded.value) {
          // eslint-disable-next-line no-plusplus
          for (let index = 1; index < search.index; index++) {
            setTimeout(() => {
              this.#ngxExtendedPdfViewerService.findNext();
            }, 200);
          }
        }
        return EMPTY;
      }),
    );
  }

  onUpdateFindMatchesCount(event: FindResultMatchesCount) {
    this.#totalFinded.next(event.total);
  }

  private resetFindFunction() {
    this.#totalFinded.next(0);
    this.#ngxExtendedPdfViewerService.find('');
    this.#ngxExtendedPdfViewerService.scrollPageIntoView(1);
  }

  ngOnDestroy(): void {
    this.#restoreLogs();
  }
}
