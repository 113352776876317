<div class="container" *ngIf="(bottomSection | async) as bottomSection ">
  <ng-container [ngTemplateOutlet]="(projectType$|async) === 'PUBLIC_TENDER' ? public: construction"> </ng-container>

  <!--order matters-->
  <ng-template #public>
    <div class="element-container">
      <span class="caption-bold">{{ 'PUBLIC.DETAIL.BOTTOM_SECTION.ACTIVITY' | translate }}:</span>
      <ng-container *ngFor="let element of bottomSection.activities; let last = last">
        <span class="caption">{{ element }}</span>
        <span *ngIf="!last" class="separator"> </span>
      </ng-container>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'PUBLIC.DETAIL.BOTTOM_SECTION.CPVS' | translate }}:</span>
      <ng-container *ngFor="let element of bottomSection.cpvs; let last = last">
        <span class="caption">{{ element }}</span>
        <span *ngIf="!last" class="separator"> </span>
      </ng-container>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'PUBLIC.DETAIL.BOTTOM_SECTION.REGION' | translate }}:</span>
      <ng-container *ngFor="let element of bottomSection.regions; let last = last">
        <span class="caption">{{ element }}</span>
        <span *ngIf="!last" class="separator"> </span>
      </ng-container>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'PUBLIC.DETAIL.BOTTOM_SECTION.ZIP_CODE' | translate }}:</span>
      <ng-container *ngFor="let element of bottomSection.zipCode; let last = last">
        <span class="caption">{{ element }}</span>
        <span *ngIf="!last" class="separator"> </span>
      </ng-container>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'PUBLIC.DETAIL.BOTTOM_SECTION.PROCEDURE_TYPE' | translate }}:</span>
      <span class="caption">{{ bottomSection.procedureType | translate }}</span>
    </div>
  </ng-template>

  <ng-template #construction>
    <div class="element-container">
      <span class="caption-bold">{{ 'CONSTRUCTION.DETAIL.BOTTOM_SECTION.PROCEDURE_TYPE' | translate }}:</span>
      <span class="caption">{{ bottomSection.procedureType | translate }}</span>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'CONSTRUCTION.DETAIL.BOTTOM_SECTION.REGION' | translate }}:</span>
      <ng-container *ngFor="let element of bottomSection.regions; let last = last">
        <span class="caption">{{ element }}</span>
        <span *ngIf="!last" class="separator"> </span>
      </ng-container>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'PUBLIC.DETAIL.BOTTOM_SECTION.ZIP_CODE' | translate }}:</span>
      <ng-container *ngFor="let element of bottomSection.zipCode; let last = last">
        <span class="caption">{{ element }}</span>
        <span *ngIf="!last" class="separator"> </span>
      </ng-container>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'CONSTRUCTION.DETAIL.BOTTOM_SECTION.SOURCE' | translate }}:</span>
      <span class="caption">{{ bottomSection.source }}</span>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'CONSTRUCTION.DETAIL.BOTTOM_SECTION.PROJECT_TYPE' | translate }}:</span>
      <ng-container *ngFor="let element of bottomSection.projectTypes; let last = last">
        <span class="caption">{{ element }}</span>
        <span *ngIf="!last" class="separator"> </span>
      </ng-container>
    </div>

    <div class="element-container">
      <span class="caption-bold">{{ 'CONSTRUCTION.DETAIL.BOTTOM_SECTION.BUILDING_TYPE' | translate }}:</span>
      <ng-container *ngFor="let element of bottomSection.buildingTypes; let last = last">
        <span class="caption">{{ element }}</span>
        <span *ngIf="!last" class="separator"> </span>
      </ng-container>
    </div>
  </ng-template>

  <div class="element-container">
    <span class="caption-bold">{{ 'PUBLIC.DETAIL.BOTTOM_SECTION.REFERENCE' | translate }}.</span>
    <span class="caption">{{ bottomSection.reference }}</span>
  </div>
</div>
