import { map, Observable, of } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { LanguageTabComponent } from '@ggp/generic/shared/components/language-tab';
import { LocaleLanguageService } from '@ggp/generic/shared/services';
import { Language as LocalLanguage, TextTranslation } from '@ggp/generic/shared/util/models';
import { TranslateModule } from '@ngx-translate/core';

interface Language {
  name: string;
  isSelected: boolean;
  isNull: boolean;
}
@Component({
  selector: 'ggp-multilang-text',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDividerModule, LanguageTabComponent],
  templateUrl: './multilang-text.component.html',
  styleUrls: ['./multilang-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultilangTextComponent implements OnInit {
  @Input({ required: true }) text!: TextTranslation;
  readonly #localeLanguageService = inject(LocaleLanguageService);

  languages$!: Observable<Language[]>;
  selectedLanguage!: string;

  ngOnInit(): void {
    this.languages$ = of(this.text).pipe(
      map((text: any) => {
        const orderedLanguages = this.orderLanguages(this.#localeLanguageService.getLocaleLanguage());

        const selectedLang = orderedLanguages.find(l => text[l] !== null) || 'nl';
        this.selectedLanguage = selectedLang;

        return orderedLanguages.map(lang => ({
          name: lang,
          isSelected: lang === selectedLang,
          isNull: text[lang] === null,
        }));
      }),
    );
  }

  getContent() {
    switch (this.selectedLanguage) {
      case 'en':
        return this.addClasses(this.text.en);

      case 'fr':
        return this.addClasses(this.text.fr);

      case 'nl':
        return this.addClasses(this.text.nl);

      case 'de':
        return this.addClasses(this.text.de);

      default:
        return this.addClasses(this.text.nl);
    }
  }

  addClasses(input: string | null) {
    const regexH1 = /<h1([^>]*)>/g;
    let modifiedContent = input?.replace(regexH1, `<h1 class="subtitle-1">`);

    const regexH2 = /<h2([^>]*)>/g;
    modifiedContent = modifiedContent?.replace(regexH2, `<h2 class="subtitle-1">`);

    const regexH3 = /<h3([^>]*)>/g;
    modifiedContent = modifiedContent?.replace(regexH3, `<h3 class="subtitle-2">`);

    const regexH4 = /<h4([^>]*)>/g;
    modifiedContent = modifiedContent?.replace(regexH4, `<h4 class="subtitle-2">`);

    return modifiedContent;
  }

  selectedLang(lang: string) {
    this.selectedLanguage = lang.toLowerCase();
  }

  orderLanguages(lang: LocalLanguage) {
    switch (lang) {
      case LocalLanguage.en:
        return ['en', 'nl', 'fr', 'de'];

      case LocalLanguage.fr:
        return ['fr', 'nl', 'en', 'de'];

      case LocalLanguage.nl:
        return ['nl', 'fr', 'en', 'de'];

      case LocalLanguage.de:
        return ['de', 'nl', 'fr', 'en'];

      default:
        return ['nl', 'fr', 'en', 'de'];
    }
  }
}
