import { inject, Injectable } from '@angular/core';
import { GGP_DEFAULT_I18N } from '@ggp/generic/i18n';
import { PLATFORM_TOKEN } from '@ggp/generic/shared/config/token';
import { Language } from '@ggp/generic/shared/util/models';

@Injectable({
  providedIn: 'root',
})
export class LocaleLanguageService {
  readonly #plateform = inject(PLATFORM_TOKEN);

  getLocaleLanguage(): Language {
    const brandDefaultLanguage = GGP_DEFAULT_I18N[this.#plateform].default;
    const currentLang = localStorage.getItem('currentLang') || brandDefaultLanguage;
    return Language[currentLang as keyof typeof Language];
  }
}
