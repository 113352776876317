// @ts-nocheck
/* eslint-disable */
import { ggpIconCloseChevronIcon } from './close-chevron';
import { ggpIconCollapseMinusIcon } from './collapse-minus';
import { ggpIconCollapsedChevronIcon } from './collapsed-chevron';
import { ggpIconDeleteIcon } from './delete';
import { ggpIconDownChevronIcon } from './down-chevron';
import { ggpIconDownloadIcon } from './download';
import { ggpIconEmailIcon } from './email';
import { ggpIconEmptyTrashIcon } from './empty-trash';
import { ggpIconExclamationMarkIcon } from './exclamation-mark';
import { ggpIconExpandPlusIcon } from './expand-plus';
import { ggpIconExpandedChevronIcon } from './expanded-chevron';
import { ggpIconExportIcon } from './export';
import { ggpIconGeneratePdfIcon } from './generate-pdf';
import { ggpIconInvoiceIcon } from './invoice';
import { ggpIconMapIcon } from './map';
import { ggpIconMyLeadsIcon } from './my_leads';
import { ggpIconNoDataIcon } from './no-data';
import { ggpIconNoNotifIcon } from './no-notif';
import { ggpIconNotifTypeConstructionIcon } from './notif-type-construction';
import { ggpIconNotifTypePublicIcon } from './notif-type-public';
import { ggpIconNotifTypeShareIcon } from './notif-type-share';
import { ggpIconOpenChevronIcon } from './open-chevron';
import { ggpIconPhoneIcon } from './phone';
import { ggpIconPlayIcon } from './play';
import { ggpIconSearchIcon } from './search';
import { ggpIconShareIcon } from './share';
import { ggpIconStarOutlineIcon } from './star-outline';
import { ggpIconStrokedEmailIcon } from './stroked_email';
import { ggpIconStrokedPhoneIcon } from './stroked_phone';
import { ggpIconTagMarkFilledIcon } from './tag-mark-filled';
import { ggpIconTagMarkIcon } from './tag-mark';
import { ggpIconUserIcon } from './user';
import { ggpIconWebsiteIcon } from './website';
export const generalIconsIcons = [ggpIconCloseChevronIcon, ggpIconCollapseMinusIcon, ggpIconCollapsedChevronIcon, ggpIconDeleteIcon, ggpIconDownChevronIcon, ggpIconDownloadIcon, ggpIconEmailIcon, ggpIconEmptyTrashIcon, ggpIconExclamationMarkIcon, ggpIconExpandPlusIcon, ggpIconExpandedChevronIcon, ggpIconExportIcon, ggpIconGeneratePdfIcon, ggpIconInvoiceIcon, ggpIconMapIcon, ggpIconMyLeadsIcon, ggpIconNoDataIcon, ggpIconNoNotifIcon, ggpIconNotifTypeConstructionIcon, ggpIconNotifTypePublicIcon, ggpIconNotifTypeShareIcon, ggpIconOpenChevronIcon, ggpIconPhoneIcon, ggpIconPlayIcon, ggpIconSearchIcon, ggpIconShareIcon, ggpIconStarOutlineIcon, ggpIconStrokedEmailIcon, ggpIconStrokedPhoneIcon, ggpIconTagMarkFilledIcon, ggpIconTagMarkIcon, ggpIconUserIcon, ggpIconWebsiteIcon];
