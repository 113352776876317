<mat-accordion>
  <mat-expansion-panel *ngFor="let item of menu; let i = index" [expanded]="i === 0 ? true : false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="subtitle-1">
          {{ item.title }}
        </div>
        <div *ngIf="item.award">
          <div class="subtitle">
            <p class="subtitle-2">{{item.award.company}}</p>
            <div *ngIf="item.award?.amount" class="aligned-items">
              <div class="horizontal-line"></div>
              <p class="body-2">{{item.award.amount.value | localizedNumber}} €</p>
            </div>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container [ngTemplateOutlet]="item.award ? awards : item.update ? updates : lots"></ng-container>

    <ng-template #awards>
      <p>awards contents</p>
    </ng-template>

    <ng-template #updates>
      <ng-container [ngTemplateOutlet]="plateform===brand.VPL ? VPL : EBP"></ng-container>

      <ng-template #VPL>
        <div class="updates-border">
          <p class="subtitle-2">Appel d'offres - BOAMP</p>
          <p class="info">Département(s) de publication: <strong>24</strong></p>
          <p class="info">Annonce No <strong>20-55266</strong></p>

          <mat-divider></mat-divider>

          <!-- <p>{{ item.updatesContent }}</p> -->
          <p style="color: var(--primary-palette-700);">I. II. IV. V. VI.</p>

          <p>
            AVIS D'ATTRIBUTION DE MARCHÉ <br />
            Directive 2014/24/UE
          </p>

          <span class="subtitle-2">Section I : Pouvoir adjudicateur</span>
          <ol type="I">
            <li>
              NOM ET ADRESSES <br />
              SMD3, La Rampinsolle, 24660, COULOUNIEIX CHAMIERS, F, Téléphone : (+33) 5 53 45 58 90, Courriel : contact&#64;smd3.fr, Fax : (+33) 5 53 45 54 99,
              Code NUTS : FRI11 <br />
              <strong>Adresse(s) internet:</strong> <br />
              Adresse principale : <a href="http://www.smd3.fr">http://www.smd3.fr</a> <br />
              Adresse du profil acheteur :
              <a href="http://www.smd3.fr/fr/marches-public/consultations-en-cours">http://www.smd3.fr/fr/marches-public/consultations-en-cours</a>
            </li>
          </ol>

          <span class="subtitle-2">Section II : Objet</span>
          <ol type="I">
            <li>ÉTENDUE DU MARCHÉ</li>
            <ol type="I">
              <li>
                Intitulé : Concession de service public exploitation et gestion de la crèche" la farandole" dans la commune de Villeneuve-la-garenne (92390)
                Numéro de référence: C22-002
              </li>
              <li>
                Code CPV principal: <br />
                Descripteur principal: 85312110 <br />
                Descripteur supplémentaire:
              </li>
              <li>
                Type de marché <br />
                Services
              </li>
              <li>
                Description succincte : Il s'agit d'une délégation de service public qui a pour objet l' exploitation et la gestion de la crèche" la farandole"
                dans la commune de Villeneuve-la-garenne (92390)
              </li>
            </ol>
          </ol>
        </div>
      </ng-template>

      <ng-template #EBP>
        <ggp-multilang-text *ngIf="item.update!.content" [text]="item.update!.content"></ggp-multilang-text>
      </ng-template>
    </ng-template>

    <ng-template #lots>
      <p *ngIf="item.amount" class="body-2">{{item.amount | localizedNumber}}</p>
      <p class="subtitle-2">{{'PUBLIC.PROFILES' | translate}} :</p>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
