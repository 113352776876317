import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  envName: 'development',
  awsAccount: '090378594288',
  awsRegion: 'eu-west-1',
  tags: {
    'IPD:TECH:ENV': 'DEV',
    'IPD:BUSINESS:BU': 'INFOLEADS',
    'IPD:BUSINESS:CUSTOMER': 'EBP',
    'IPD:BUSINESS:PROJECT': 'GGP',
    'IPD:TECH:STACK': 'EBP/GGP',
    'map-migrated': 'mig36286',
  },
  api: {
    appsyncApiId: '',
  },
  assets: {
    bucket: 'ggp-ebp-assets-development',
    distributionId: '',
    domainName: '',
  },
  cognito: {
    admin: {
      userPoolId: '',
      userPoolName: '',
    },
    abk: {
      userPoolId: '',
      userPoolName: '',
    },
    ebp: {
      userPoolId: 'eu-west-1_OwFVuKo83',
      userPoolName: 'ggp-ebp-user-pool-development',
    },
    mpf: {
      userPoolId: '',
      userPoolName: '',
    },
  },
  dataBucket: 'ggp-ebp-integration-snowflake-exports',
  docDb: {
    secret: '/ggp/v2/documentdb/masteruser',
    arn: 'arn:aws:secretsmanager:eu-west-1:090378594288:secret:/ggp/v2/documentdb/masteruser-ulWe6B',
  },
  docmanager: {
    credentials: {
      secret: '/ggp/docmanager/credentials',
      arn: 'arn:aws:secretsmanager:eu-west-1:090378594288:secret:/ggp/docmanager/credentials-wceBbi',
    },
    token: {
      secret: '/ggp/docmanager/token',
      arn: 'arn:aws:secretsmanager:eu-west-1:090378594288:secret:/ggp/docmanager/token-ouffCt',
    },
  },
  ecrion: {
    secret: '/ggp/ecrion',
    arn: 'arn:aws:secretsmanager:eu-west-1:090378594288:secret:/ggp/ecrion-3qRk9w',
    workspace: 'GGP_test',
    publicProjectPath: 'ebp-test/public-tender-timeline/main_public_tender_timeline.epr',
    privateProjectPath: 'ebp-test/private_project_timeline/main_private_project_timeline.epr',
  },
  eventbridge: {
    name: 'EbpBackOfficeEventBus-integration',
    source: 'ggp',
  },
  justask: {
    url: '',
  },
  opensearch: {
    name: 'capgemini-opensearch-sandbox',
    domainName: 'https://search-capgemini-opensearch-sandbox-f2e5zy3zlwnbdu3vnxoaljbmka.eu-west-1.es.amazonaws.com/',
    dataImport: {
      maxConcurrency: 2,
    },
    secrets: {
      readOnly: {
        name: '',
        arn: '',
      },
      readWrite: {
        name: '',
        arn: '',
      },
    },
  },
  vpc: {
    vpcId: '',
    privateSubnets: [],
  },
  bo: {
    apiGqlUrl: 'https://zxmy3d3xnfaehjr6li5ydkimfq.appsync-api.eu-west-1.amazonaws.com/graphql',
    apiKey: 'da2-ril3ymd4abay5hatdzd75gybgq',
    brandinfoBaseUrl: 'https://assets-backoffice.integration.aws.ebp.be',
  },
  queues: {
    docManagerImport: {
      arn: '',
      url: '',
    },
    docManagerFetch: {
      arn: '',
      url: '',
    },
    documentImport: {
      arn: '',
      url: '',
    },
    documentSearchmatch: {
      arn: '',
      url: '',
    },
    documentSearchmatchResult: {
      arn: '',
      url: '',
    },
    documentSearchmatchNotify: {
      arn: '',
      url: '',
    },
    notification: {
      arn: '',
      url: '',
    },
    notificationSender: {
      arn: '',
      url: '',
    },
    sharingNotification: {
      arn: '',
      url: '',
    },
    userActionLogs: {
      arn: '',
      url: '',
    },
    userDataHistoricalProfileMatches: undefined,
    userDataOrganisationStatus: undefined,
    userDataProfileMatches: undefined,
    userDataProjectStatus: undefined,
  },
  strapi: {
    arn: '',
    name: '',
  },
  brand: {
    baseUrl: '',
    publicUrl: '/public-projects?projectType=PUBLIC_TENDER&id=',
    privateUrl: '/construction-projects?projectType=PRIVATE_PROJECT&id=',
  },
  features: {
    showCompanies: true,
    showCurrentStageOnly: true,
    showJustAsk: true,
    showMigrationLogin: true,
    showDemoProfile: true,
  },
};
