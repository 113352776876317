export const didomi = (w: Window, didomi_id: string) => {
  if (!didomi_id) throw new Error('Missing didomi id from environment files');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (w as any).didomi_id = didomi_id;

  const didomiHeadScript = w.document.createElement('script');
  didomiHeadScript.src = '/assets/scripts/didomi.js';

  w.document.head.appendChild(didomiHeadScript);
};
