import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ggpLarge]',
  standalone: true,
})
export class LargeDirective {
  @HostBinding('class') get classes(): string {
    return 'large';
  }
}
