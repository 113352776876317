import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

interface Language {
  name: string;
  isSelected: boolean;
  isNull: boolean;
}
@Component({
  selector: 'ggp-language-tab',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './language-tab.component.html',
  styleUrls: ['./language-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageTabComponent implements OnInit {
  @Input() data!: Language[] | null;
  @Output() selectedLang = new EventEmitter<string>();

  selectLang(index: number) {
    if (this.data && !this.data[index].isNull) {
      this.data[index].isSelected = true;
      this.data.filter(item => this.data && item.name !== this.data[index].name).map(item => (item.isSelected = false));
      this.selectedLang.emit(this.data[index].name);
    }
  }

  ngOnInit(): void {
    const nonNullLanguages = this.data?.filter(language => !language.isNull);
    if (nonNullLanguages && nonNullLanguages.length === 1) {
      this.selectedLang.emit(nonNullLanguages[0].name);
    }
  }
}
