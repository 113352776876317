import { APP_CONFIG, AppConfig } from '@ggp/generic/shared/config/app';
import { MissingTranslationHandler, TranslateLoader } from '@ngx-translate/core';

import { AppTranslateLoader } from './localization';
import { WarnMissingTranslationHandler } from './missingTranslationHandler';

export const translationLoader = {
  loader: {
    provide: TranslateLoader,
    useFactory: (environment: AppConfig) => new AppTranslateLoader(environment),
    deps: [APP_CONFIG],
  },
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: WarnMissingTranslationHandler,
  },
};
