import { ProductType } from '@ggp/generic/shared/config/token';

export const ProductTypes: ProductType = {
  ACDC_PUBLIC: 'acdc-public',
  AC_PUBLIC: 'ac-public',
  DC_PUBLIC: 'dc-public',
  OPENING: 'opening',
  AWARD: 'award',
  EXPIRY_ALERT: 'expiry-alert',
};
