import { gql } from 'apollo-angular';

export const editUserDataMutationGQL = gql`
  mutation editUserDataMutation($userData: UserData) {
    editUserDataMutation(userData: $userData){
      statusCode
      message
    }
  }
`;

export const markProjectFavoriteMutationGQL = gql`
  mutation markProjectFavoriteMutation($userData: UserData) {
    markProjectFavoriteMutation(userData: $userData){
      statusCode
      message
    }
  }
`;
