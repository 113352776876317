export interface Filter {
  filtersSelected: FilterSelected[];
}

export interface FilterSelected {
  parentKey: string;
  value: any;
  displayText?: any;
  title?: string;
  color?: string;
  children?: string[];
  currentStageOnly?: boolean;
}

export interface Range {
  minValue?: Date | number;
  maxValue?: Date | number;
  type?: string;
  optional?: boolean;
}

export interface DisplayTextDetails extends Range {
  label: string;
}

export enum ProjectSizeFilterKeys {
  SURFACE = 'surface',
  COST_ESTIMATE = 'cost_estimate',
  BUILDINGS_NUMBER = 'buildings_number',
  FLOORS_NUMBER = 'floors_number',
  HOUSES_NUMBER = 'houses_number',
  DEMOLITION_VOLUME = 'demolition_volume',
  CONTRACT_VALUE = 'project_size', // TODO remove when BE ready
}
