<ul>
  <li
    [attr.data-test-id]="item.data_test_id"
    [attr.data-cy]="item.data_test_id"
    *ngFor="let item of items$ | async; let i = index"
    routerLink="{{ item.redirection }}"
    [routerLinkActive]="'active'"
    [queryParams]="item.queryParams"
    (click)="clearFilters()"
  >
    <svg-icon [key]="item.key"></svg-icon>
  </li>
</ul>
