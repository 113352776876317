<ng-container *ngIf="hasWithkeywordsProduct(); else subscriptionView">
  <ngx-extended-pdf-viewer
    *ngIf="getPdfUrl()"
    [src]="getPdfUrl()"
    [showBorders]="true"
    [sidebarVisible]="true"
    [language]="currentLocale"
    [theme]="'dark'"
    [logLevel]="0"
    [zoom]="'auto'"
    [customToolbar]="customToolbar"
    [findbarVisible]="highlightScroll ? true : false"
    [textLayer]="true"
    (pdfLoaded)="pdfLoaded($event)"
    (updateFindMatchesCount)="onUpdateFindMatchesCount($event)"
  ></ngx-extended-pdf-viewer>
  <ng-template #customToolbar>
    <div id="toolbarViewer">
      <div id="toolbarViewerLeft">
        <pdf-toggle-sidebar></pdf-toggle-sidebar>
        <pdf-find-button [textLayer]="true"></pdf-find-button>
        <pdf-paging-area></pdf-paging-area>
      </div>
      <pdf-zoom-toolbar></pdf-zoom-toolbar>
      <div id="toolbarViewerRight">
        <pdf-rotate-page></pdf-rotate-page>
        <pdf-presentation-mode></pdf-presentation-mode>
        <pdf-print></pdf-print>
        <button class="download-icon" (click)="downloadPdf()">
          <svg-icon key="download" size="sm"></svg-icon>
        </button>
      </div>
    </div>
  </ng-template>
  <ggp-no-preview *ngIf="!getPdfUrl()"></ggp-no-preview>
</ng-container>
<ng-template #subscriptionView>
  <div *ngIf="hasWithkeywordsProduct() !== null" class="dce-subscription">
    <ggp-upgrade-subscription [data]="subscriptionData"></ggp-upgrade-subscription>
  </div>
</ng-template>
