import { Apollo, MutationResult } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { actionSubject, EntityType } from '@ggp/generic/shared/util/models';

import { ActionMethodes } from './models';

@Injectable({
  providedIn: 'root',
})
export class ActionButtonService implements ActionMethodes {
  readonly actionAddedToProject = new BehaviorSubject<actionSubject>({ id: '', action: '' });
  readonly #apollo = inject(Apollo);

  favorite(projectIds: string[], type: EntityType): Observable<MutationResult> {
    throw new Error('Method not implemented.');
  }
  unfavorite(projectIds: string[], type: EntityType): Observable<MutationResult> {
    throw new Error('Method not implemented.');
  }
  read(projectIds: string[]): Observable<MutationResult> {
    throw new Error('Method not implemented.');
  }
  unread(projectIds: string[]): Observable<MutationResult> {
    throw new Error('Method not implemented.');
  }
  delete(projectIds: string[]): Observable<MutationResult> {
    throw new Error('Method not implemented.');
  }
  export(projectIds: string[]): void {
    throw new Error('Method not implemented.');
  }
  addLabel(projectIds: string[]): void {
    throw new Error('Method not implemented.');
  }
  restore(listOfIds: string[]): Observable<MutationResult> {
    throw new Error('Method not implemented.');
  }
}
