<ng-container *ngIf="locations | async as locations">
  <div>
    <div *ngIf="projectType === 'PRIVATE_PROJECT'">
      <div class="subtitle-1 space-elements">{{ 'PUBLIC.DETAIL.BUILDING_LOCATION' | translate }}</div>
      <div *ngFor="let location of locations" class="space-elements">
        <div class="location-icon">
          <svg-icon key="map-pin" size="s" class="map-pin-icon"></svg-icon><span class="subtitle-2">{{ getLocation(location) }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="hasCoordinates(locations)">
      <ggp-map [style]="'street'" class="map" [locations]="locations"></ggp-map>
    </div>
  </div>
</ng-container>
