import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ggp-no-preview',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './no-preview.component.html',
  styleUrls: ['./no-preview.component.scss'],
})
export class NoPreviewComponent {}
