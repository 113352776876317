import { gql } from '@apollo/client';
export const getProjectGeneralInfoByIdGQL = gql`
  query getProjectGeneralInformationQuery($projectId: String!, $language: Language!, $stage: String!, $keywords: [String]) {
    getProjectGeneralInformationQuery(projectId: $projectId, language: $language, stage: $stage, keywords: $keywords){
      id
      lastModificationDate
      creationDate
      title
      regions {
        value
      }
      deadlineDate
      hasDocuments
      activities
      links {
        source
        link
      }
      companyNames
      estimatedValue{
        minValue
        maxValue
      }
      surfaces{
        value
        minValue
        maxValue
      }
      startDate
      tags{
          label
          color
      }
      isFavorite
      isRead
    }
  }
`;

export const getProjectAuthoriesByIdGQL = gql`
query getProjectAuthoritiesQuery($projectId: String!, $language: Language!, $allContacts: Boolean!) {
  getProjectAuthoritiesQuery(projectId: $projectId, language: $language, allContacts: $allContacts){
      name
      countryCode
      address
      zipCode
      role
      city
      email
      phone
      website
      contacts{
        id
        role
        firstName
        lastName
        email
        phone
      }
    }
}
`;

export const getProjectPublicationTextByIdGQL = gql`
  query getProjectPublicationTextQuery($projectId: String!, $stage: String!, $keywords: [String]) {
    getProjectPublicationTextQuery(projectId: $projectId, stage: $stage, keywords: $keywords){
      en
      de
      nl
      fr
    }
  }
`;

export const getProjectBottomSectionByIdGQL = gql`
  query getProjectBottomSectionQuery($projectId: String!, $language: Language!, $stage: String!) {
    getProjectBottomSectionQuery(projectId: $projectId, language: $language, stage: $stage){
      reference
      activities
      cpvs
      regions
      zipCode
      procedureType
      projectTypes
      buildingTypes
      source
    }
  }
`;
export const getProjectRenewalDatesGQL = gql`
  query getProjectRenewalDatesQuery($projectId: String!) {
    getProjectRenewalDatesQuery(projectId: $projectId){
      startDate
      firstPossibleEndDate
      lastPossibleEndDate
    }
  }`;

export const getProjectRectificationsGQL = gql`
  query getProjectRectificationsQuery($projectId: String!, $keywords: [String]) {
    getProjectRectificationsQuery(projectId: $projectId, keywords: $keywords) {
      text{
            nl
            fr
            en
            de
        }
        publicationDate
    }
  }`;

export const getProjectLotsByIdGQL = gql`
  query getProjectLotsQuery($projectId: String!, $stage: String!) {
    getProjectLotsQuery(projectId: $projectId, stage: $stage){
      amount
      companyName
      lotNumber
    }
  }
`;

export const getProjectLocationsGQL = gql`
  query getProjectLocationsQuery($projectId: String!, $language: Language!) {
    getProjectLocationsQuery(projectId: $projectId, language: $language) {
      type
      countryCode
      role
      postCode
      address
      city
      value
      code
    }
  }
`;
