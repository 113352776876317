import { EMPTY, firstValueFrom, lastValueFrom, of } from 'rxjs';

import { APP_INITIALIZER, FactoryProvider, inject } from '@angular/core';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { GGP_DEFAULT_I18N } from '@ggp/generic/i18n';
import { BRAND_TOKEN, PLATFORM_TOKEN } from '@ggp/generic/shared/config/token';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from './services/authentication.service';
import { ProductData, ProductsService } from '@ggp/generic/shared/services';

export function impersonationUserFactory(authenticationService: AuthenticationService) {
  return async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('userId');

      if (userId) {
        const userInfo = await getCurrentUser();

        if (userId === userInfo.username) {
          return await fetchAuthSession({ forceRefresh: true });
        } else {
          return firstValueFrom(authenticationService.signOut()).then(() => location.reload);
        }
      }
    } catch (error) {
      console.error(error);
    }
    return Promise.resolve();
  };
}

export function loadCurrentUserFactory(authenticationService: AuthenticationService, productService: ProductsService) {
  const { isBackOffice } = inject(BRAND_TOKEN);

  for (const key in localStorage) {
    if (key.endsWith('.accessToken')) {
      return () => {
        getCurrentUser()
          .then(cognitoUser => {
            authenticationService.cognitoUser = cognitoUser;
          })
          .then(() => {
            return isBackOffice ? new Promise<ProductData>(resolve => resolve({} as ProductData)) : lastValueFrom(productService.getProducts());
          })
          .then((data: ProductData) => (productService.products = data));
      };
    }
  }

  return () =>
    new Promise((resolve, reject) => {
      resolve(EMPTY);
    });
}

export function loadCurrentLangFactory(translate: TranslateService, brand = inject(PLATFORM_TOKEN)) {
  const currentLang = localStorage.getItem('currentLang');
  return () =>
    new Promise((resolve, reject) => {
      if (currentLang) {
        translate.use(currentLang);
      } else {
        const defaultLang = GGP_DEFAULT_I18N[brand].default;
        translate.setDefaultLang(defaultLang);
        translate.use(defaultLang);
      }
      resolve(EMPTY);
    });
}

export const loadCurrentProvider: FactoryProvider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: loadCurrentUserFactory,
    deps: [AuthenticationService, ProductsService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: loadCurrentLangFactory,
    deps: [TranslateService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: impersonationUserFactory,
    deps: [AuthenticationService],
    multi: true,
  },
];
