import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from '@ggp/generic/auth';
import { LargeDirective } from '@ggp/generic/shared/directives/element/size';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ggp-page-404',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, LargeDirective, RouterModule],
  templateUrl: './page-404.component.html',
  styleUrls: ['./page-404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Page404Component {
  readonly isAuthenticated = !!inject(AuthenticationService).cognitoUser;
}
