// @ts-nocheck
/* eslint-disable */
import { ggpIconArrowDirectionIcon } from './arrow-direction';
import { ggpIconBookIcon } from './book';
import { ggpIconCloseChevronIcon } from './close-chevron';
import { ggpIconCloseIcon } from './close';
import { ggpIconConstructionIcon } from './construction';
import { ggpIconExpandIcon } from './expand';
import { ggpIconHomeIcon } from './home';
import { ggpIconLeagueIcon } from './league';
import { ggpIconMoreMenuIcon } from './more-menu';
import { ggpIconOpenChevronIcon } from './open-chevron';
import { ggpIconQuestionCircleIcon } from './question-circle';
import { ggpIconReduceIcon } from './reduce';
import { ggpIconStarIcon } from './star';
import { ggpIconSubscriptionIcon } from './subscription';
import { ggpIconTrashIcon } from './trash';
import { ggpIconTriangleUpIcon } from './triangle-up';
export const leftBarIcons = [ggpIconArrowDirectionIcon, ggpIconBookIcon, ggpIconCloseChevronIcon, ggpIconCloseIcon, ggpIconConstructionIcon, ggpIconExpandIcon, ggpIconHomeIcon, ggpIconLeagueIcon, ggpIconMoreMenuIcon, ggpIconOpenChevronIcon, ggpIconQuestionCircleIcon, ggpIconReduceIcon, ggpIconStarIcon, ggpIconSubscriptionIcon, ggpIconTrashIcon, ggpIconTriangleUpIcon];
