import { map, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@ggp/generic/shared/config/app';

interface SearchCriterias {
  searchText: string;
  country: string;
}

interface LatLng {
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: 'root',
})
export class GeoCodingService {
  private readonly httpClient = inject(HttpClient);

  constructor(@Inject(APP_CONFIG) private environment: AppConfig) {}

  getLocationByText(searchCriterias: SearchCriterias) {
    return this.httpClient
      .get(`${this.environment.endpoints.mapbox.geocoding_url}/${searchCriterias.searchText}.json`, {
        params: {
          access_token: this.environment.endpoints.mapbox.access_token,
          country: searchCriterias.country,
          types: 'country,region,postcode,place',
          limit: 1,
        },
      })
      .pipe(
        map((data: any) => {
          return data.features.length ? of(data.features[0].center) : null;
        }),
      );
  }

  getLocationByCoordinates(latLng: LatLng) {
    return this.httpClient.get(`${this.environment.endpoints.mapbox.geocoding_url}/${latLng.longitude},${latLng.latitude}.json`, {
      params: {
        access_token: this.environment.endpoints.mapbox.access_token,
      },
    });
  }
}
