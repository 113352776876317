export enum ActionButtons {
  FAVORITE = 'favorite',
  UNFAVORITE = 'unfavorite',
  FAVORITE_UNFAVORITE = 'favorite-unfavorite',
  ADD_LABEL = 'addLabel',
  EXPORT = 'export',
  DELETE = 'delete',
  RESTORE = 'restore',
  GENERATE_PDF = 'genetarePdf',
  READ = 'read',
  UNREAD = 'unread',
  READ_UNREAD = 'read-unread'
}

export type EditActionButtons = ActionButtons.FAVORITE | ActionButtons.UNFAVORITE | ActionButtons.RESTORE | ActionButtons.READ | ActionButtons.UNREAD;
