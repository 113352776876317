import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, HttpLinkHandler } from 'apollo-angular/http';
import { fetchAuthSession } from 'aws-amplify/auth';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { FactoryProvider } from '@angular/core';
import { ApolloLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { AppConfig } from '@ggp/generic/shared/config/app';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    if ((graphQLErrors[0] as any)?.statusCode >= 400 && (graphQLErrors[0] as any)?.statusCode < 500) {
      console.error(`[Client side error]: ${graphQLErrors[0].message}`);
    } else {
      console.error(`[Server side error]: ${graphQLErrors[0].message}`);
    }
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError.message}`);
  }
});

const basicContext = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Accept: 'charset=utf-8',
      authorization: `Bearer token`,
      'Content-Type': 'application/json',
    },
  };
});

export const apolloGraphqlProvider = (environment: AppConfig): FactoryProvider => ({
  provide: APOLLO_OPTIONS,
  useFactory(httpLink: HttpLink) {
    const cache = new InMemoryCache({});

    const http = httpLink.create({
      uri: environment.endpoints.app.uri,
    });

    const url = environment.endpoints.app.uri;
    const region = 'eu-west-1';
    const auth = {
      type: 'AMAZON_COGNITO_USER_POOLS' as const,
      jwtToken: async () => {
        const currentSession = await fetchAuthSession();
        const idToken = currentSession.tokens?.idToken;
        if (!idToken) {
          throw new Error('No valid ID token found');
        }
        return idToken.toString();
      },
    };
    const wsLink = createSubscriptionHandshakeLink({ url, region, auth }, http);

    const splitLink = createSplitLink(wsLink, http);

    return {
      connectToDevTools: true,
      assumeImmutableResults: true,
      cache,
      link: splitLink,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all',
        },
      },
    };
  },
  deps: [HttpLink],
});

const createSplitLink = (wsLink: ApolloLink, http: HttpLinkHandler) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink as unknown as ApolloLink,
    ApolloLink.from([basicContext, errorLink, http]),
  );
};
