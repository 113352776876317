<div class="container" *ngIf="projectGeneralInfo | async as selectedProject">
  <h6 class="stage-name">{{ currentStage | translate }}</h6>

  <ng-container>
    <span *ngIf="selectedProject.creationDate" class="publication-date body-2">
      <span class="last-update" *ngIf="isProjectUpdated$ | async">
        {{ 'PUBLIC.DETAIL.UPDATE_BOX' | translate }}: {{ selectedProject.lastModificationDate | localizedDate }}
      </span>
      <span>{{ 'PUBLIC.DETAIL.PUBLISHED' | translate }}: {{ selectedProject.creationDate | localizedDate }}</span>
    </span>

    <h4 class="title" [innerHTML]="selectedProject.title"></h4>
    <div class="dce-buttons">
      <button *ngIf="selectedProject.hasDocuments" ggpSmall mat-flat-button color="primary" (click)="openDocs(selectedProject.links, selectedProject.id)">
        <svg-icon key="attachment" size="sm"></svg-icon>
        {{ 'PUBLIC.DETAIL.DCE' | translate }}
      </button>
      <ggp-link [originalLinks]="selectedProject.links"></ggp-link>
    </div>

    <div class="detail-box">
      <div class="authority" #section>
        <p class="body-2">{{ 'PUBLIC.DETAIL.AUTHORITY' | translate }}:</p>
        <p id="authority-text" style="line-height: 24px;" class="subtitle-1 authority-p">
          <span *ngFor="let name of selectedProject.companyNames" class="separator" [innerHTML]="name | highlight: searchKeywords"> </span>
        </p>
        <p class="subtitle-1 action">
          <span (click)="handleAction('authority','show')">{{ 'PUBLIC.DETAIL.SHOW_MORE' | translate }}</span>
          <span (click)="handleAction('authority','hide')">{{ 'PUBLIC.DETAIL.HIDE' | translate }}</span>
        </p>
      </div>

      <div class="location" #section>
        <p class="body-2">{{ 'PUBLIC.DETAIL.REGION' | translate }}:</p>
        <p id="location-text" style="line-height: 24px;" class="subtitle-1 location-p">
          <span *ngFor="let region of getRegions(selectedProject.regions)" class="separator">
            {{ region }}
          </span>
        </p>
        <p class="subtitle-1 action">
          <span (click)="handleAction('location','show')">{{ 'PUBLIC.DETAIL.SHOW_MORE' | translate }}</span>
          <span (click)="handleAction('location','hide')">{{ 'PUBLIC.DETAIL.HIDE' | translate }}</span>
        </p>
      </div>

      <div class="activities" #section>
        <p class="body-2">{{ 'PUBLIC.DETAIL.ACTIVITIES' | translate }}:</p>
        <p style="line-height: 24px;" class="subtitle-1 activities-p">
          <span *ngFor="let activity of selectedProject.activities" class="separator">
            <span>{{ activity }}</span>
          </span>
        </p>
        <p class="subtitle-1 action">
          <span (click)="handleAction('activities','show')">{{ 'PUBLIC.DETAIL.SHOW_MORE' | translate }}</span>
          <span (click)="handleAction('activities','hide')">{{ 'PUBLIC.DETAIL.HIDE' | translate }}</span>
        </p>
      </div>

      <div class="deadline">
        <p class="body-2">{{ 'PUBLIC.DETAIL.DEADLINE' | translate }}:</p>
        <p [ngClass]="((selectedProject.deadlineDate | deadLineDays) === 0) ? 'subtitle-1 expired-date' : 'subtitle-1'">
          {{ selectedProject.deadlineDate | localizedDate }}
        </p>
        <div
          [class]="'date-box deadline-' +((selectedProject.deadlineDate | deadLineDays) <= 10 ? '10' : '30')"
          data-test-id="ggp-public-leads-table-last-publication-status"
          *ngIf="(selectedProject.deadlineDate | deadLineDays) <= 30 && (selectedProject.deadlineDate | deadLineDays) > 0"
        >
          <caption>
            {{ selectedProject.deadlineDate | deadLineDays}}
            {{
              'DATE.DEADLINE' | translate
            }}
          </caption>
        </div>
      </div>
    </div>
    <div class="tags">
      <mat-chip-set #chipGrid>
        <mat-chip-row *ngFor="let label of selectedProject.tags" (removed)="remove(selectedProject.id, label)" [style]="getLabelBorder(label.color)">
          <span class="subtitle-2">{{label.label}}</span>
          <button matChipRemove [attr.aria-label]="'remove ' + label.label">
            <svg-icon key="close" size="xs"></svg-icon>
          </button>
        </mat-chip-row>
      </mat-chip-set>
    </div>
  </ng-container>
  <div class="no-data-section" *ngIf="projectDetails | async as project">
    <div *ngIf="isDataNotAvailable(project)">
      <h4>{{ 'PUBLIC.DETAIL.NO_DATA' | translate }}</h4>

      <div class="no-data-img">
        <h4 class="blue-box">{{ 'PUBLIC.DETAIL.NO_DATA_BOX' | translate }}</h4>
      </div>
    </div>
  </div>
</div>
