import { Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Authority } from '@ggp/ebp/leads/util';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ggp-authorities',
  standalone: true,
  templateUrl: './/autorities.component.html',
  styleUrls: ['./autorities.component.scss'],
  imports: [CommonModule, TranslateModule, SvgIconComponent],
})
export class AuthoritiesComponent {
  @Input() projectAuthorities$!: Observable<Authority[]>;
}
