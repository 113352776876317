import { Apollo } from 'apollo-angular';
import { BehaviorSubject, forkJoin, map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Stage } from '@ggp/ebp/data/util';
import { ExecutionSite, Language } from '@ggp/ebp/leads/util';
import { CompanyDetailViewData, DetailViewData, getExcelFileUrlGQL, Leads } from '@ggp/generic/shared/services';
import { ContactBoxDetails, Filters, ProjectType, Sort } from '@ggp/generic/shared/util/models';

import { ProjectQuery } from '../query/projectQuery';
import {
  getProjectAuthoriesByIdGQL,
  getProjectBottomSectionByIdGQL,
  getProjectGeneralInfoByIdGQL,
  getProjectLocationsGQL,
  getProjectLotsByIdGQL,
  getProjectPublicationTextByIdGQL,
  getProjectRectificationsGQL,
  getProjectRenewalDatesGQL,
} from '../schema/projectSchema';
import { getAllProjectsGQL, getProjectStatusGQL, getPublicProfilesGQL } from '../schema/schema';

@Injectable({
  providedIn: 'root',
})
export class EpbLeadsService implements Leads {
  readonly #apollo = inject(Apollo);

  detailViewData$ = new BehaviorSubject<DetailViewData | CompanyDetailViewData | null>(null);

  getProjects(projectType: ProjectType, pageNumber: number, language: string, sort?: Sort, filters?: Filters) {
    return this.#apollo.query<ProjectQuery>({
      query: getAllProjectsGQL,
      variables: {
        projectType,
        pagination: {
          pageNumber,
        },
        sort,
        language,
        filters,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getProjectStatus(projectId: string) {
    return this.#apollo.query<ProjectQuery>({
      query: getProjectStatusGQL,
      variables: {
        projectId: projectId,
      },
    });
  }

  getPublicProfiles(projectType: ProjectType) {
    return this.#apollo.query<ProjectQuery>({
      query: getPublicProfilesGQL,
      variables: {
        projectType,
      },
    });
  }

  getPresigneUrl(projectIds: string[], language: string, columns: string[], excelType: string, projectType: ProjectType, refLabel: string) {
    return this.#apollo
      .query<{ getExcelFileUrlQuery: string }>({
        query: getExcelFileUrlGQL,
        variables: {
          projectIds,
          language,
          columns,
          excelType,
          projectType,
          refLabel,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data;
        }),
      );
  }

  getProjectGeneralInfoById(projectId: string, language: string, stage: string, keywords?: string[]) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectGeneralInfoByIdGQL,
        variables: {
          projectId,
          language,
          stage,
          keywords,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.getProjectGeneralInformationQuery;
        }),
      );
  }

  getProjectPublicationTextById(projectId: string, stage: string, keywords?: string[]) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectPublicationTextByIdGQL,
        variables: {
          projectId: projectId,
          stage: stage,
          keywords: keywords,
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.getProjectPublicationTextQuery;
        }),
      );
  }

  getAuthorities(projectId: string, language: string, allContacts: boolean) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectAuthoriesByIdGQL,
        variables: {
          projectId,
          language,
          allContacts,
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.getProjectAuthoritiesQuery;
        }),
      );
  }

  getProjectBottomSectionById(projectId: string, language: string, stage: string) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectBottomSectionByIdGQL,
        variables: {
          projectId: projectId,
          language: language,
          stage: stage,
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.getProjectBottomSectionQuery;
        }),
      );
  }

  getProjectLotsById(projectId: string, stage: string) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectLotsByIdGQL,
        variables: {
          projectId: projectId,
          stage: stage,
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.getProjectLotsQuery;
        }),
      );
  }

  getProjectRenewalDates(projectId: string) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectRenewalDatesGQL,
        variables: {
          projectId,
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.getProjectRenewalDatesQuery;
        }),
      );
  }

  getProjectRectificationsQuery(projectId: string, keywords?: string[]) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectRectificationsGQL,
        variables: {
          projectId,
          keywords,
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.getProjectRectificationsQuery;
        }),
      );
  }

  getProjectLocations(projectId: string, language: string) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectLocationsGQL,
        variables: {
          projectId,
          language,
        },
      })
      .pipe(
        map(({ data }) => {
          const locations: any[] = [];
          data?.getProjectLocationsQuery?.forEach(location => {
            if (location.code !== 'citygroup-16268' && location.code !== 'region-42') {
              const mappedLocation = this.getMapedLocation(location);
              locations.push(mappedLocation);
            }
          });
          return locations;
        }),
      );
  }

  getMapedLocation(location: ExecutionSite) {
    return {
      type: location.type,
      city: {
        code: location.city,
        label: location.city,
      },
      address: location.address,
      departement: {
        code: '',
        label: '',
      },
      region: {
        code: location.value,
        label: location.value,
      },
      role: location.role,
      country: {
        code: location.countryCode?.toLowerCase(),
        label: location.countryCode,
      },
      mapIcon: {
        color: location.role === 'EXECUTION_SITE' ? 'var(--execution-point)' : 'var(--authority-point)',
        url: location.role === 'EXECUTION_SITE' ? 'assets/icons/detail-view/work.svg' : 'assets/icons/detail-view/municipality.svg',
        type: 'work',
        size: '20px',
      },
      location: null,
      value: location.value,
      postCode: location.postCode,
    };
  }

  getContactBoxDetails(projectId: string, language: string, allContacts: boolean) {
    return this.#apollo
      .query<ProjectQuery>({
        query: getProjectAuthoriesByIdGQL,
        variables: {
          projectId,
          language,
          allContacts,
        },
      })
      .pipe(
        map(({ data }) => {
          const contactBox: ContactBoxDetails[] = [];
          data.getProjectAuthoritiesQuery.forEach(contact => {
            contactBox.push({
              role: contact.role,
              companyName: contact.name,
              address: {
                city: contact.city,
                exactAddress: contact.address,
                postalCode: contact.zipCode,
              },
              phoneNumber: contact.phone,
              website: contact.website,
              email: contact.email,
              contacts: contact.contacts,
            });
          });
          return contactBox;
        }),
      );
  }

  getPublicDetailView(projectId: string, stage: string, language: Language = Language.en, allContacts: boolean, keywords?: string[]) {
    const detailViewObj: any = {
      generalInformation: this.getProjectGeneralInfoById(projectId, language, stage, keywords),
      bottomSection: this.getProjectBottomSectionById(projectId, language, stage),
    };

    switch (stage) {
      case Stage.PRIOR_INFORMATION:
      case Stage.TENDER:
        detailViewObj.authorities = this.getAuthorities(projectId, language, allContacts);
        detailViewObj.publicationText = this.getProjectPublicationTextById(projectId, stage, keywords);
        detailViewObj.locations = this.getProjectLocations(projectId, language);
        break;

      case Stage.ERRATA:
        detailViewObj.rectifications = this.getProjectRectificationsQuery(projectId, keywords);
        break;

      case Stage.OPENING:
      case Stage.AWARD:
        detailViewObj.publicationText = this.getProjectPublicationTextById(projectId, stage, keywords);
        detailViewObj.lots = this.getProjectLotsById(projectId, stage);
        break;

      case Stage.EXPIRY_ALERT:
        detailViewObj.publicationText = this.getProjectPublicationTextById(projectId, stage, keywords);
        detailViewObj.renewalDates = this.getProjectRenewalDates(projectId);
        break;

      default:
        throw new Error('Invalid stage');
    }

    return forkJoin(detailViewObj);
  }

  getConstructionDetailView(projectId: string, stage: string, language: Language = Language.en, allContacts: boolean, keywords?: string[]) {
    return forkJoin({
      generalInformation: this.getProjectGeneralInfoById(projectId, language, stage, keywords),
      publicationText: this.getProjectPublicationTextById(projectId, stage, keywords),
      bottomSection: this.getProjectBottomSectionById(projectId, language, stage),
      contactBoxDetails: this.getContactBoxDetails(projectId, language, allContacts),
      locations: this.getProjectLocations(projectId, language),
    });
  }

  updateDetailViewData(data: DetailViewData | CompanyDetailViewData | null): void {
    this.detailViewData$.next(data);
  }
}
