import * as mapboxgl from 'mapbox-gl';

import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@ggp/generic/shared/config/app';
import { Brand } from '@ggp/generic/shared/config/token';
import { ExecutionSite } from '@ggp/generic/shared/util/models';

type MapStyle = 'street';

@Component({
  selector: 'ggp-map',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  private map!: mapboxgl.Map;

  @Input() style!: MapStyle;

  @Input() locations!: ExecutionSite[];

  @Input() iconsUrls!: string[];

  dimensionsObserver: ResizeObserver;

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig, private host: ElementRef) {
    this.dimensionsObserver = new ResizeObserver(() => {
      this.map.resize();
    });
    this.dimensionsObserver.observe(this.host.nativeElement);
  }

  ngOnInit() {
    this.initializeMap();

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      this.locations.forEach(l => this.createMarker(l));
      if (this.locations.length === 1) {
        const firstLocation = this.locations[0];
        this.setSingleLocation(firstLocation);
      } else if (this.locations.length > 1) {
        const validLocations = this.locations.filter(location => this.isValidLocation(location));
        if (validLocations.length > 0) {
          const validLatLngs = validLocations.map(location => ({ lon: location.location.lng, lat: location.location.lat }));
          this.fitMapToLocations(validLatLngs);
        }
      }
    });
  }

  private initializeMap(): void {
    if (this.map) {
      this.destroyMap();
    }

    this.map = new mapboxgl.Map({
      accessToken: this.appConfig.endpoints.mapbox.access_token,
      container: 'map',
      style: this.appConfig.endpoints.mapbox.style[this.style],
      zoom: 13,
      attributionControl: false,
    });
  }

  private createMarker(location: ExecutionSite) {
    const el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundColor = location.mapIcon.color;
    el.style.backgroundImage = `url(${location.mapIcon.url})`;
    el.style.backgroundSize = location.mapIcon.size;
    el.style.backgroundRepeat = 'no-repeat';
    el.style.backgroundPosition = 'center';
    el.style.position = 'absolute';
    if (Brand.EBP) {
      el.classList.add(location.role && location.role === 'EXECUTION_SITE' ? 'custom-execution-style' : 'custom-authority-style');
    }
    return new mapboxgl.Marker(el).setLngLat([location.location.lng, location.location.lat]).addTo(this.map);
  }

  private setSingleLocation(location: any): void {
    this.map.setCenter([location.location.lng, location.location.lat]);
  }

  private fitMapToLocations(coordinates: { lon: number; lat: number }[]): void {
    const bounds = coordinates.reduce((acc, coord) => acc.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
    this.map.fitBounds(bounds, { padding: 64, maxZoom: 13 });
  }

  private isValidLocation(location: { location: { lng: number; lat: number } }): boolean {
    return typeof location.location.lng === 'number' && typeof location.location.lat === 'number';
  }

  private destroyMap(): void {
    if (this.map) {
      this.map.remove();
    }
  }

  ngOnDestroy() {
    this.destroyMap();
    this.dimensionsObserver.unobserve(this.host.nativeElement);
  }
}
