import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  interval,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil
} from 'rxjs';

import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DataLoadingDirective } from '@ggp/generic/shared/directives/data-loading';
import { LargeDirective, SmallDirective } from '@ggp/generic/shared/directives/element/size';
import { LoadingDirective } from '@ggp/generic/shared/directives/loading';
import { LocalizedDatePipe, TextHighlightPipe } from '@ggp/generic/shared/pipes';
import {
  DceDownloadResponse,
  DisplayPdf,
  DocsService,
  File,
  FileService,
  ToasterService
} from '@ggp/generic/shared/services';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ggp-all-documents',
  standalone: true,
  imports: [
    CommonModule,
    LargeDirective,
    SmallDirective,
    MatInputModule,
    MatIconModule,
    SvgIconComponent,
    TranslateModule,
    MatCheckboxModule,
    LoadingDirective,
    MatButtonModule,
    DataLoadingDirective,
    FormsModule,
    TextHighlightPipe,
    MatProgressSpinnerModule,
  ],
  providers: [LocalizedDatePipe],
  templateUrl: './all-documents.component.html',
  styleUrls: ['./all-documents.component.scss', '../shared-docs-styles.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AllDocumentsComponent implements OnChanges, AfterViewInit, OnDestroy {
  readonly #docsService = inject(DocsService);
  readonly #toasterService = inject(ToasterService);
  readonly #translate = inject(TranslateService);
  readonly #onDestroy$ = new Subject();
  readonly #fileService = inject(FileService);
  readonly #cd = inject(ChangeDetectorRef);

  @Output() displayPdfEvent = new EventEmitter<DisplayPdf>();
  @Input({ required: true }) docs: File[] | null = [];
  @Input({ required: true }) documentId!: string;

  @ViewChild('docsSearch', { read: ElementRef }) docsSearch!: ElementRef<HTMLInputElement>;

  clickedIndex: number | null = null;
  selectedFiles = new SelectionModel<File>(true, []);
  dataIsLoading = new BehaviorSubject<boolean | null>(false);
  currentOpenFile?: File;
  hoveredFile?: File | null;

  downloadFilesIsLoading = false;
  downloadFileIsLoading = false;

  searchText = '';
  searchTermChanged = new Subject<string>();
  filteredDocs$!: Observable<File[]>;

  ngAfterViewInit() {
    fromEvent(this.docsSearch.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((e: any) => e.target.value),
        filter((text: string) => text.length > 2 || text.length === 0),
        takeUntil(this.#onDestroy$),
      )
      .subscribe((text: string) => {
        this.updateFilteredDocuments(text);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['docs']) {
      if (changes['docs'].firstChange) this.updateFilteredDocuments('');

      this.displayDefaultPdf();
    }
  }

  private updateFilteredDocuments(searchString: string): void {
    const filteredDocuments = this.getFilteredDocuments(searchString);
    this.filteredDocs$ = of(filteredDocuments ?? []);
    this.#cd.detectChanges();
  }

  private getFilteredDocuments(searchTerm: string): File[] | undefined {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filteredDocuments = this.docs?.filter(document => {
      return `${document.name}.${document.extension}`.toLowerCase().includes(lowerCaseSearchTerm) || searchTerm.length === 0;
    });

    this.clickedIndex = filteredDocuments?.findIndex(doc => doc === this.currentOpenFile) ?? -1;
    return filteredDocuments;
  }

  onSearchChange(searchText: string) {
    this.searchTermChanged.next(searchText);
  }

  onMouseEnter(file: File) {
    this.hoveredFile = file;
  }

  onMouseLeave() {
    this.hoveredFile = null;
  }

  currentItemHovered(file: File): boolean {
    return file.id === this.hoveredFile?.id || file.id === this.currentOpenFile?.id;
  }

  isAllSelected() {
    return this.selectedFiles.selected.length === this.docs?.length;
  }

  private clearSelectedDocuments() {
    this.selectedFiles.clear();
  }

  toggleAllDocuments() {
    if (this.isAllSelected()) {
      this.clearSelectedDocuments();
      return;
    }

    const docs = this.docs ?? [];
    this.selectedFiles.select(...docs);
  }

  displayPdf(event: MouseEvent, file: File) {
    event.preventDefault();
    this.emitPdf(file);
  }

  private displayDefaultPdf() {
    const file = this.docs ? this.docs[0] : null;
    if (file) {
      this.clickedIndex = 0;
      this.emitPdf(file);
    }
  }

  private emitPdf(file: File) {
    this.currentOpenFile = file;
    if (!file.extension) file.extension = file.name.split('.')[1];
    this.displayPdfEvent.emit({
      documentId: this.documentId,
      file: file,
    });
  }

  downloadFile(file: File): void {
    file.isLoading = true;
    interval(5000)
      .pipe(
        switchMap(() => this.#docsService.downloadFile(this.documentId, [file.id])),
        filter((response: DceDownloadResponse) => {
          if (!response) {
            throw new Error('Response is null');
          }
          return response?.progress === 1.0 && response?.status === 'done' && response?.url !== null;
        }),
        take(1),
        catchError(err => {
          file.isLoading = false;
          this.#toasterService.openToaster(this.#translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.#translate.instant('ERRORS.SERVER.TITLE'));
          this.#cd.detectChanges();
          throw err;
        }),
      )
      .subscribe(response => {
        file.isLoading = false;
        this.#fileService.download(response?.url, file.name);
        this.#cd.detectChanges();
      });
  }

  downloadSelectedFiles(): void {
    this.downloadFilesIsLoading = true;

    const filesIds = this.selectedFiles.selected ? this.selectedFiles.selected.map(f => f.id) : this.docs?.map(f => f.id);
    if (filesIds) {
      interval(5000)
        .pipe(
          switchMap(() => this.#docsService.downloadFile(this.documentId, filesIds)),
          filter((response: DceDownloadResponse) => {
            if (!response) {
              throw new Error('Response is null');
            }
            return response?.progress === 1.0 && response?.status === 'done' && response?.url !== null;
          }),
          take(1),
          catchError(err => {
            this.downloadFilesIsLoading = false;
            this.#toasterService.openToaster(this.#translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.#translate.instant('ERRORS.SERVER.TITLE'));
            this.#cd.detectChanges();
            throw err;
          }),
        )
        .subscribe(response => {
          this.downloadFilesIsLoading = false;
          if (filesIds.length === 1) {
            const selectedFile = this.docs?.find(file => file.id === filesIds[0]);
            this.#fileService.download(response?.url, selectedFile?.name);
          } else {
            this.#fileService.download(response?.url);
          }
          this.#cd.detectChanges();
        });
    }
  }

  shouldShowDownloadIcon(i: number, doc: File): boolean {
    return this.currentItemHovered(doc) && !doc.isLoading && !this.downloadFilesIsLoading;
  }

  ngOnDestroy(): void {
    this.#onDestroy$.next(null);
    this.#onDestroy$.complete();
  }
}
