<button
  mat-button
  class="notification-btn"
  [matBadge]="(notificationCpt$ | async)! > maxCountToDisplay ? '9+' : (notificationCpt$ | async)"
  [matBadgeHidden]="(notificationCpt$ | async) === 0"
  data-test-id="ggp-topbar-notifications"
  [matMenuTriggerFor]="menu"
  (menuOpened)="menuOpened()"
  data-cy="notifications"
>
  <svg-icon key="notification"></svg-icon>
</button>

<mat-menu #menu class="notification-menu">
  <div class="header" (click)="$event.stopPropagation();">
    <h6>{{ 'NOTIFICATION.TITLE' | translate }}</h6>
    <mat-slide-toggle [checked]="unreadNotifications" (change)="unreadNotifications = $event.source.checked" labelPosition="before">
      {{ 'NOTIFICATION.TOGGLE' | translate }}
    </mat-slide-toggle>
  </div>
  <div class="body" (click)="$event.stopPropagation();">
    <div [ggpDataLoading]="dataIsLoading$ | async">
      <span class="data-loader"></span>
    </div>
    <ng-container *ngIf="(dataIsLoading$ | async) === false">
      <!-- Today notifications -->
      <ng-container *ngIf="today">
        <div class="notification-date" [ngStyle]="{'flex-direction': getList(today).length > 0 ? 'row': 'row-reverse'}">
          <span class="overline" *ngIf="getList(today).length > 0">{{ 'NOTIFICATION.TODAY_TITLE' | translate }}</span>
          <a
            (click)="markAllAsRead()"
            class="caption"
            *ngIf="getUnreadCounter(today) > 0 || getUnreadCounter(yesterday) > 0 || getUnreadCounter(older) > 0"
            >{{ 'NOTIFICATION.MARK_AS_ALL' | translate }}</a
          >
        </div>
        <div class="notification-row" *ngFor="let notification of getList(today)">
          <div class="notification-type">
            <svg-icon
              [key]="notification.notificationType === 'NEW_PUBLIC_TENDERS' ? 'notif-type-public' : notification.notificationType === 'NEW_PRIVATE_PROJECTS' ? 'notif-type-construction':'notif-type-share'"
              [ngClass]="{'public-icon': notification.notificationType === 'NEW_PUBLIC_TENDERS', 'construction-icon': notification.notificationType === 'NEW_PRIVATE_PROJECTS', 'share-icon' : notification.notificationType === 'LEAD_SHARE'}"
            ></svg-icon>
          </div>
          <div class="notifiaction-content">
            <p class="subtitle-1">{{ notification.notificationType | translate: { lastName: notification.content[0].user?.lastName} }}</p>
            <div class="notification-chips" *ngIf="notification.notificationType !== 'LEAD_SHARE'; else sharedBlock">
              <mat-chip *ngFor="let profile of notification.content" (click)="redirectUser(notification, 'today', profile.profileId)">
                <span class="subtitle-2">{{profile.title}}</span
                ><span class="body-2">{{profile.count}}</span>
              </mat-chip>
            </div>
            <ng-template #sharedBlock>
              <div class="notification-shared">
                <svg-icon [key]="getIcon(notification)" size="sm"></svg-icon>
                <a class="share-caption" (click)="redirectUser(notification, 'today')">
                  {{notification.content[0].projectTitle}}
                </a>
              </div>
            </ng-template>
          </div>
          <button mat-mini-fab class="read-dot" *ngIf="!notification.seen" (click)="markNotificationAsRead(notification, 'today')"></button>
        </div>
      </ng-container>

      <!-- yesterday notifications -->
      <ng-container *ngIf="yesterday">
        <div class="notification-date" *ngIf="getList(yesterday).length > 0">
          <span class="overline">{{ 'NOTIFICATION.YESTERDAY_TITLE' | translate }}</span>
        </div>
        <div class="notification-row" *ngFor="let notification of getList(yesterday)">
          <div class="notification-type">
            <svg-icon
              [key]="notification.notificationType === 'NEW_PUBLIC_TENDERS' ? 'notif-type-public' : notification.notificationType === 'NEW_PRIVATE_PROJECTS' ? 'notif-type-construction':'notif-type-share'"
              [ngClass]="{'public-icon': notification.notificationType === 'NEW_PUBLIC_TENDERS', 'construction-icon': notification.notificationType === 'NEW_PRIVATE_PROJECTS', 'share-icon' : notification.notificationType === 'LEAD_SHARE'}"
            ></svg-icon>
          </div>
          <div class="notifiaction-content">
            <p class="subtitle-1">{{ notification.notificationType | translate: { lastName: notification.content[0].user?.lastName} }}</p>
            <div class="notification-chips" *ngIf="notification.notificationType !== 'LEAD_SHARE'; else sharedBlock">
              <mat-chip *ngFor="let profile of notification.content" (click)="redirectUser(notification, 'yesterday', profile.profileId)">
                <span class="subtitle-2">{{profile.title}}</span
                ><span class="body-2">{{profile.count}}</span>
              </mat-chip>
            </div>
            <ng-template #sharedBlock>
              <div class="notification-shared">
                <svg-icon [key]="getIcon(notification)" size="sm"></svg-icon>
                <a class="share-caption" (click)="redirectUser(notification, 'yesterday')">
                  {{notification.content[0].projectTitle}}
                </a>
              </div>
            </ng-template>
          </div>
          <button mat-mini-fab class="read-dot" *ngIf="!notification.seen" (click)="markNotificationAsRead(notification, 'yesterday')"></button>
        </div>
      </ng-container>

      <!-- older notifications -->
      <ng-container *ngIf="older">
        <div class="notification-date" *ngIf="getList(older).length > 0">
          <span class="overline">{{ 'NOTIFICATION.OLDER_TITLE' | translate }}</span>
        </div>
        <div class="notification-row" *ngFor="let notification of getList(older)">
          <div class="notification-type">
            <svg-icon
              [key]="notification.notificationType === 'NEW_PUBLIC_TENDERS' ? 'notif-type-public' : notification.notificationType === 'NEW_PRIVATE_PROJECTS' ? 'notif-type-construction':'notif-type-share'"
              [ngClass]="{'public-icon': notification.notificationType === 'NEW_PUBLIC_TENDERS', 'construction-icon': notification.notificationType === 'NEW_PRIVATE_PROJECTS', 'share-icon' : notification.notificationType === 'LEAD_SHARE'}"
            ></svg-icon>
          </div>
          <div class="notifiaction-content">
            <p class="subtitle-1">{{ notification.notificationType | translate: { lastName: notification.content[0].user?.lastName} }}</p>
            <div class="notification-chips" *ngIf="notification.notificationType !== 'LEAD_SHARE'; else sharedBlock">
              <mat-chip *ngFor="let profile of notification.content" (click)="redirectUser(notification, 'older', profile.profileId)">
                <span class="subtitle-2">{{profile.title}}</span
                ><span class="body-2">{{profile.count}}</span>
              </mat-chip>
            </div>
            <ng-template #sharedBlock>
              <div class="notification-shared">
                <svg-icon [key]="getIcon(notification)" size="sm"></svg-icon>
                <a class="share-caption" (click)="redirectUser(notification, 'older')">
                  {{notification.content[0].projectTitle}}
                </a>
              </div>
            </ng-template>
          </div>
          <button mat-mini-fab class="read-dot" *ngIf="!notification.seen" (click)="markNotificationAsRead(notification, 'older')"></button>
        </div>
      </ng-container>
    </ng-container>

    <ng-container
      class="no-notification"
      *ngIf="(dataIsLoading$ | async) === false && unreadNotifications && getUnreadCounter(today) === 0 && getUnreadCounter(yesterday) === 0 && getUnreadCounter(older) === 0"
    >
      <div class="no-notification">
        <svg-icon key="no-notif"></svg-icon>
        <p innerHTML="{{ 'NOTIFICATION.NO_NOTIF' | translate }}"></p>
      </div>
    </ng-container>
  </div>
</mat-menu>
