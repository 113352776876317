import {
  ComponentRef,
  Directive,
  inject,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: '[ggpLoading]',
  standalone: true
})
export class LoadingDirective implements OnChanges {
  private readonly matButton = inject(MatButton, {optional: true});
  private readonly matIconButton = inject(MatIconButton, {optional: true});
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly renderer = inject(Renderer2);

  @Input() ggpLoading = false;
  @Input() disabled = false;
  @Input() color: ThemePalette;

  private spinner!: ComponentRef<MatProgressSpinner> | null;

  ngOnChanges(changes: SimpleChanges): void {
    const button = this.matIconButton || this.matButton;
    if (!changes['ggpLoading'] || !button) {
      return;
    }

    if (changes['ggpLoading'].currentValue) {
      button._elementRef.nativeElement.classList.add('button-loading');
      button.disabled = true;
      this.createSpinner();
    } else if (!changes['ggpLoading'].firstChange) {
      button._elementRef.nativeElement.classList.remove(
        'button-loading'
      );
      button.disabled = this.disabled;
      this.destroySpinner();
    }
  }

  private createSpinner(): void {
    if (!this.spinner) {
      const button = this.matIconButton || this.matButton;
      this.spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
      this.spinner.instance.color = this.color;
      this.spinner.instance.diameter = this.matIconButton ? 24 : 20;
      this.spinner.instance.mode = 'indeterminate';
      this.renderer.appendChild(
        button?._elementRef.nativeElement,
        this.spinner.instance._elementRef.nativeElement
      );
    }
  }

  private destroySpinner(): void {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
