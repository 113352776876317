import {
  DemoProfileAttribute,
  DocumentType,
  KeywordLookupLocation,
  LaunchSearchProfileParams,
  ProjectType,
  SearchProfileAttribute,
  SearchProfileType,
  TenderTypeGroup
} from '@ggp/ebp/data/util';
import { classificationHandler, keywordsHandler, locationsHandler } from '@ggp/ebp/leads/util';
import { QueryDslQueryContainer } from '@opensearch-project/opensearch/api/types';

import publicSources from '../assets/public-sources';
import { HIGHLIGHTS } from '../constants/highlights';

const publicTypeGroups = Object.values(TenderTypeGroup).filter(v => v !== TenderTypeGroup.PRIVATEMARKET);
const keywordOnDocumentLookups = [KeywordLookupLocation.DOCUMENTS, KeywordLookupLocation.PROJECT_OR_DOCUMENTS];

export class SearchProfileOpensearchConverter {
  hasKeywordsToSearchOnDocuments = false;
  hasKeywordsToSearchAllExceptOnDocuments = true;
  constructor(
    private attributes: SearchProfileAttribute | DemoProfileAttribute,
    private searchProfileType: SearchProfileType,
    private launchSearchProfileParams?: LaunchSearchProfileParams,
  ) {
    this.hasKeywordsToSearchOnDocuments = this.attributes.keywords?.some(kw => kw.lookup && keywordOnDocumentLookups.includes(kw.lookup));
    this.hasKeywordsToSearchAllExceptOnDocuments = this.attributes.keywords?.some(kw => kw.lookup !== KeywordLookupLocation.DOCUMENTS);
  }

  getOpensearchQuery = (documentType: DocumentType = 'tender', toLaunch = false): QueryDslQueryContainer => {
    if (toLaunch && documentType === 'tender') {
      return this.hasKeywordsToSearchOnDocuments ? this.buildTenderWithDocumentsQuery(documentType) : this.buildTenderQuery(documentType);
    }

    return this.getFullQuery(documentType);
  };

  private buildTenderWithDocumentsQuery(documentType: DocumentType): QueryDslQueryContainer {
    const documentQueryPart = this.buildDocumentQueryPart(documentType);

    return this.hasKeywordsToSearchAllExceptOnDocuments
      ? {
          bool: {
            should: [this.buildTenderQuery(documentType), { ...documentQueryPart }],
          },
        }
      : documentQueryPart;
  }

  private buildTenderQuery(documentType: DocumentType): QueryDslQueryContainer {
    const tenderQuery = {
      has_child: {
        type: 'tender',
        query: this.getFullQuery(documentType),
        inner_hits: {
          _source: false,
          highlight: HIGHLIGHTS,
        },
      },
    };
    return tenderQuery;
  }

  private buildDocumentQueryPart(documentType: DocumentType): QueryDslQueryContainer {
    return {
      bool: {
        filter: [
          {
            has_child: {
              type: 'tender',
              query: this.getFullQuery(documentType, false),
            },
          },
          {
            has_child: {
              type: 'document',
              query: {
                bool: keywordsHandler(this.attributes.keywords.filter(kw => kw.lookup && keywordOnDocumentLookups.includes(kw.lookup))),
              },
              inner_hits: {
                _source: false,
                highlight: HIGHLIGHTS,
              },
            },
          },
        ],
      },
    };
  }

  private getFullQuery = (documentType: DocumentType = 'tender', withKeywords = true): QueryDslQueryContainer => {
    const filters: QueryDslQueryContainer[] = [];

    console.log('Profile to convert: ', this.attributes);

    // Add document type filters
    filters.push(this.getDocumentTypeFilter(documentType));

    // Add classification filters
    filters.push(...classificationHandler(this.attributes, documentType));

    // Add location filters
    filters.push(...locationsHandler(this.attributes, documentType));

    // Add source filters
    if (this.attributes.sources?.length) {
      filters.push(this.getSourceFilters(documentType));
    }

    // Add date range filters
    if (this.launchSearchProfileParams) {
      const { to, from, period } = this.launchSearchProfileParams;
      if (to || from || (period !== undefined && period > 0)) {
        filters.push(this.getDateRangeFilter(documentType, this.launchSearchProfileParams));
      }
    }

    // Handle keywords
    const keywordsQuery = withKeywords ? keywordsHandler(this.attributes.keywords) : undefined;

    return {
      bool: {
        filter: [...filters, ...(keywordsQuery?.filter ?? [])],
        must_not: keywordsQuery?.must_not,
        should: keywordsQuery?.should,
      },
    };
  };

  private getDocumentTypeFilter(documentType: DocumentType): QueryDslQueryContainer {
    if (documentType === 'project') {
      return {
        term: {
          'project.type': this.searchProfileType === SearchProfileType.PUBLIC ? ProjectType.PUBLIC_TENDER : ProjectType.PRIVATE_PROJECT,
        },
      };
    }

    return this.searchProfileType === SearchProfileType.PUBLIC
      ? { terms: { 'tender.typeGroup': publicTypeGroups } }
      : { term: { 'tender.typeGroup': TenderTypeGroup.PRIVATEMARKET } };
  }

  private getSourceFilters(documentType: DocumentType): QueryDslQueryContainer {
    const sources =
      this.searchProfileType === SearchProfileType.PUBLIC
        ? [
            ...new Set(
              publicSources.filter(publicSource => this.attributes.sources?.includes(publicSource.value)).flatMap(publicSource => publicSource.sources),
            ),
          ]
        : this.attributes.sources ?? [];

    return this.getSourceFilter(documentType, sources);
  }

  private getSourceFilter(documentType: string, sources: string[]) {
    const termsFilter = { terms: { 'tender.source': sources } };

    return documentType === 'project' ? { has_child: { type: 'tender', query: termsFilter } } : termsFilter;
  }

  getDateRangeFilter(documentType: string, params: LaunchSearchProfileParams) {
    const field = documentType === 'project' ? 'project.planification.lastStateModificationDate' : 'tender.planification.publishDate';

    const rangeFilter = {
      range: {
        [field]: {
          lte: params.to ? new Date(params.to).toJSON() : undefined,
          gte: params.from ? new Date(params.from).toJSON() : `now-${Math.abs(params.period ?? 1)}M/M`,
        },
      },
    };

    return rangeFilter;
  }
}
