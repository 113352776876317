// @ts-nocheck
/* eslint-disable */
import { ggpIconChatIcon } from './chat';
import { ggpIconEmailIcon } from './email';
import { ggpIconFacebookIcon } from './facebook';
import { ggpIconLinkedinIcon } from './linkedin';
import { ggpIconPhoneIcon } from './phone';
import { ggpIconWebsiteIcon } from './website';
import { ggpIconYoutubeIcon } from './youtube';
export const contactIcons = [ggpIconChatIcon, ggpIconEmailIcon, ggpIconFacebookIcon, ggpIconLinkedinIcon, ggpIconPhoneIcon, ggpIconWebsiteIcon, ggpIconYoutubeIcon];
