import { Pipe, PipeTransform } from '@angular/core';
import { IStage } from '@ggp/generic/shared/util/models';

@Pipe({
  name: 'publicNoticeStatePipe',
  standalone: true,
})
export class PublicNoticeStatePipe implements PipeTransform {
  transform(stages: IStage[]): string {
    const currentStage = stages.find(({ isCurrent }) => isCurrent);
    return currentStage?.type || '';
  }
}
