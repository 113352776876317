import { forkJoin, from, map, Observable, Subject } from 'rxjs';

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { AuthenticationService } from '@ggp/generic/auth';
import { LanguageSwitcherComponent } from '@ggp/generic/shared/components/language-switcher';
import { NotificationComponent } from '@ggp/generic/shared/components/notification';
import { BRAND_TOKEN, DEFAULT_ROUTE_TOKEN, PLATFORM_TOKEN } from '@ggp/generic/shared/config/token';
import { SubscriptionService } from '@ggp/generic/shared/services';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

interface ImpersonatedUser {
  first_name: string;
  last_name: string;
}
interface MenuItems {
  id: number;
  label: string;
  value: string;
}
@Component({
  selector: 'ggp-top-bar',
  standalone: true,
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    LanguageSwitcherComponent,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    SvgIconComponent,
    NotificationComponent,
    RouterLink,
    NgOptimizedImage,
  ],
})
export class TopBarComponent implements OnInit, OnDestroy {
  private readonly authenticationService = inject(AuthenticationService);
  readonly #subscriptionService = inject(SubscriptionService);
  readonly showNotifications = this.#subscriptionService.showNotifications;
  private readonly router = inject(Router);
  readonly #onDestroy$ = new Subject();
  plateform = inject(PLATFORM_TOKEN);
  backoffice = inject(BRAND_TOKEN);
  defaultRoute = inject(DEFAULT_ROUTE_TOKEN);

  impersonatedUser$!: Observable<ImpersonatedUser | null>;
  menuItems: MenuItems[] = [
    {
      id: 1,
      label: 'HEADER.PROFILE',
      value: 'profile-link',
    },
    {
      id: 1,
      label: 'HEADER.LOG_OUT',
      value: 'sign-out',
    },
  ];

  ngOnInit() {
    this.impersonatedUser$ = forkJoin([from(fetchUserAttributes()), this.#subscriptionService.getMyTeam()]).pipe(
      map(([userAttributes, team]) => {
        const attributes = (userAttributes || {}) as Record<string, string | undefined>;
        if (!('custom:impersonate' in attributes)) return null;
        const impersonatedUser: ImpersonatedUser = team.find((user: { email: string }) => user.email === attributes['custom:impersonate']);
        return { first_name: impersonatedUser.first_name, last_name: impersonatedUser.last_name };
      }),
    );
  }

  onItemClick(value: string) {
    if (value === 'profile-link') {
      // redirect to MY ACCOUNT page
      this.router.navigate(['/account']);
    } else {
      this.authenticationService.signOut().subscribe(() => {
        location.reload();
      });
    }
  }

  ngOnDestroy(): void {
    this.#onDestroy$.next(null);
    this.#onDestroy$.complete();
  }
}
