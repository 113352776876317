import { gql } from '@apollo/client';

export const getLessonsGQL = gql`
  query getLessonsQuery($locale: String!, $scope: String!) {
    getLessonsQuery(locale: $locale, scope: $scope){
        id
        key
        value
        name
        lessons {
            id
            description
            title
            content
            attachments {
              id
              name
              type
              url
            }
        }
    }
  }
`;

export const getManualLinkGQL = gql`
  query getManualLinkQuery($locale: Language!, $scope: String!){
    getManualLinkQuery(locale: $locale, scope: $scope){
      url
      name
    }
  }
`;
