import { Observable, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { ProjectBottomSection } from '@ggp/ebp/leads/util';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ggp-bottom-section',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './bottom-section.component.html',
  styleUrls: ['./bottom-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSectionComponent implements OnInit {
  readonly #route = inject(ActivatedRoute);

  @Input() bottomSection!: Observable<ProjectBottomSection>;
  projectType$!: Observable<string>;

  ngOnInit(): void {
    this.projectType$ = this.#route.queryParams.pipe(map(params => params['projectType']));
  }
}
