import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { SvgIcons } from '@ngneat/svg-icon';

export enum CustomStageType {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ACTIVE = 'active',
  CANCELED = 'canceled',
  OPENED = 'opened',
}

export type CustomStage = Array<{ index: number; key: SvgIcons; state: CustomStageType; isCurrent: boolean }>;

@Injectable({
  providedIn: 'root',
})
export class ProjectStagesService {
  private currentStage$ = new BehaviorSubject<number | null>(null);
  private customStage$ = new BehaviorSubject<CustomStage>([]);

  currentStageId$ = this.currentStage$.asObservable();

  get currentStage(): number | null {
    return this.currentStage$.value;
  }

  set currentStage(v: number | null) {
    this.currentStage$.next(v);
  }

  get customStages$(): Observable<CustomStage> {
    return this.customStage$;
  }

  get customStages(): CustomStage {
    return this.customStage$.getValue();
  }

  set customStages(v: CustomStage) {
    this.customStage$.next(v);
  }

  shouldDisplayLastUpdated(stages: CustomStageType[]) {
    return stages.filter(stage => [CustomStageType.ACTIVE, CustomStageType.ENABLED].includes(stage))?.length > 1;
  }
}
