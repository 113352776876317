import { gql } from '@apollo/client';

export const getUsersGQL = gql`
query getUsersQuery{
  getUsersQuery{
    user_id
    first_name
    last_name
    email
    }
  }
`;

export const getMyTeamGQL = gql`
query getUsersQuery{
  getUsersQuery{
    user_id
        title
        first_name
        last_name
        is_admin
        email
        language_code
        profiles {
            profileId
            profileName
            notify
            subscriptionProfileType
        }
    }
  }
`;
