import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deadLineDays',
  standalone: true,
})
export class DeadLineDaysPipe implements PipeTransform {
  transform(date: string) {
    const timeDifference = new Date(date).getTime() - new Date().getTime();
    const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    return days > 0 ? days : 0;
  }
}
