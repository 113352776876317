import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatProjetType',
  standalone: true,
})
export class FormatProjetTypePipe implements PipeTransform {
  transform(projectType: string[]): string {
    return projectType?.join(', ')?.replace(/\//g, ' / ') || '';
  }
}
