<div>
  <span class="title"> {{ data.title }} </span>
  <span class="message"> {{ data.message }} </span>
</div>

<div>
  <span matSnackBarActions>
    <mat-icon [ngClass]="data.type === 'info' ? 'info-color' : ''" fontIcon="close" (click)="dismissWithAction()" />
  </span>
</div>
