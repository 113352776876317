import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'ggp-custom-snackbar',
  standalone: true,
  imports: [NgClass, MatIconModule, MatButtonModule, MatProgressBarModule, MatSnackBarModule],
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
})
export class CustomSnackbarComponent {
  private readonly snackBarRef = inject(MatSnackBarRef);
  public data = inject(MAT_SNACK_BAR_DATA);

  dismissWithAction() {
    this.snackBarRef.dismissWithAction();
  }
}
