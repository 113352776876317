<div class="source-container" *ngIf="!!originalLinks?.length">
  <button
    mat-button
    #triggerButton
    class="go-to-source"
    disableRipple
    [matMenuTriggerFor]="menu"
    aria-label="View original publications"
    data-cy="original-source"
  >
    <svg-icon key="link-to" size="sm"></svg-icon>
    {{ 'PUBLIC.DETAIL.GO_TO_SOURCE' | translate }}
  </button>

  <mat-menu #menu="matMenu" yPosition="below" class="dropdown-list">
    <div [style.min-width.px]="triggerButton._elementRef.nativeElement.offsetWidth">
      <ng-container *ngFor="let linkData of originalLinks">
        <a mat-menu-item [href]="linkData.link" target="_blank" [attr.data-cy]="linkData.source">
          <p class="body-2" *ngIf="!(linkData.source === tedLink || linkData.source === threePLink); else noTitleCaseSourceLink">
            {{ (linkData.source === otherLabel ? otherLink : linkData.source) | translate | titlecase }}
          </p>
          <ng-template #noTitleCaseSourceLink>
            <p class="body-2">
              {{ linkData.source | translate }}
            </p>
          </ng-template>
        </a>
      </ng-container>
    </div>
  </mat-menu>
</div>
