import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { GGP_DEFAULT_I18N } from '@ggp/generic/i18n';
import { TranslateService } from '@ngx-translate/core';

interface LanguageI18N {
  language: string;
  name: string;
  local: string;
}

@Component({
  selector: 'ggp-language-switcher',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule],
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent implements OnInit {
  @Input() plateform!: string;
  @Input() disabled!: boolean;

  translate = inject(TranslateService);
  currentLang = this.translate.currentLang;
  supportedLanguages: LanguageI18N[] = [];

  ngOnInit(): void {
    if (Object.keys(GGP_DEFAULT_I18N).includes(this.plateform)) {
      Object.values(GGP_DEFAULT_I18N[this.plateform].languages).map(single => {
        if (single.translatePlatform === undefined || single.translatePlatform === true) {
          this.supportedLanguages.push(single);
        }
      });
    } else {
      throw Error(`The tag ${this.plateform} does not exist within the GGP platforms, please check the GGP_DEFAULT_I18N.`);
    }
    const storedCurrentLang = localStorage.getItem('currentLang');
    if (storedCurrentLang) {
      this.currentLang = storedCurrentLang;
      this.translate.use(storedCurrentLang);
    }
  }

  onLanguageChange(key: string) {
    this.currentLang = key;
    this.translate.use(key);
    localStorage.setItem('currentLang', key);
    window.location.reload();
  }
}
