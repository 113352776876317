import { SvgIcons } from '@ngneat/svg-icon';

export interface IStagesList {
  id: number;
  key: SvgIcons;
}
export const CONTRUCTION_STAGE_LIST: IStagesList[] = [
  {
    id: 0,
    key: 'intention',
  },
  {
    id: 1,
    key: 'design',
  },
  {
    id: 2,
    key: 'preparation',
  },
  {
    id: 3,
    key: 'execution',
  },
  {
    id: 4,
    key: 'finished',
  },
];

export const DISPLAYED_COLUMNS: string[] = ['select', 'stage', 'description', 'region', 'update', 'deadline', 'dce'];
