import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compareDate',
  standalone: true,
})
export class CompareDatePipe implements PipeTransform {
  transform(dateTime1: string | null | undefined, dateTime2: string | null | undefined): boolean {
    if (!dateTime1 || !dateTime2) {
      return false;
    }

    const date1 = dateTime1.split('T')[0];
    const date2 = dateTime2.split('T')[0];

    return date1 === date2;
  }
}
