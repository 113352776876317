import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[ggpSmall]',
  standalone: true,
})
export class SmallDirective {
  @HostBinding('class') get classes(): string {
    return 'small';
  }
}
