<ng-container *ngFor="let lang of data let index=index">
  <button
    mat-button
    [ngClass]="lang.isSelected ? 'selected': 'notSelected'"
    color="primary"
    class="submit-btn"
    mat-flat-button
    (click)="selectLang(index)"
    [attr.data-cy]="'active-'+lang.name"
    *ngIf="!lang.isNull"
  >
    <caption>
      {{ lang.name }}
    </caption>
  </button>
</ng-container>
