import { gql } from 'apollo-angular';

export const getGdprGql = gql`
  query getGdprQuery($locale: String!, $scope: String!){
    getGdprQuery(locale: $locale, scope: $scope){
        brand
        head
        id
        updatedDate
        updatedText
        sections{
            content
            title
        }
    }
}
`;
