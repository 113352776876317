import { InjectionToken } from '@angular/core';

import { DEFAULT_PROFILE_DETAILS_ROUTES } from '../route/profile-details-menu.route';
import { PlatformConfig } from './platform-config.interface';

export const DEFAULT_PLATFORM_CONFIG: PlatformConfig = {
  constructionProjectsColumns: ['select', 'infos', 'project-type', 'description', 'region', 'start-date', 'last-update'],
  constructionProjectDetailsColumns: ['authority', 'construction-cost', 'surface', 'construction-start'],
  profileDetailsRoutes: DEFAULT_PROFILE_DETAILS_ROUTES,
};

export const PLATFORM_CONFIG_TOKEN = new InjectionToken<PlatformConfig>('platform-config', {
  providedIn: 'root',
  factory: () => DEFAULT_PLATFORM_CONFIG,
});
