<ng-container *ngIf="hasWithkeywordsProduct() ; else subscriptionView">
  <div class="container" *ngIf="docsWithKeyWords">
    <div class="download-section">
      <button [ggpLoading]="downloadFilesIsLoading" ggpSmall color="primary" (click)="downloadSelectedFiles()" mat-flat-button>
        <svg-icon key="download" size="sm"></svg-icon>
        {{ 'DOCS.DOWNLOAD_FILES' | translate }}
      </button>
    </div>
    <div class="file-content" *ngFor="let file of docsWithKeyWords">
      <div class="icons">
        <svg-icon [key]="file.expanded?'collapse-minus':'expand-plus'" size="md" (click)="file.expanded = !file.expanded"></svg-icon>
      </div>
      <div class="content">
        <p class="doc-name" [ngClass]="{'subtitle-2': file.id === selectedFile?.id, 'body-2': file.id !== selectedFile?.id}" (click)="displayPdf($event, file)">
          {{ file.name }}
        </p>
        <div class="body-2 doc-preview" [ngClass]="{'doc-preview-collapsed': !file.expanded}">
          <div *ngFor="let item of file.highlightedResults; let i = index">
            <span [innerHTML]="getContent(item.showMore ? item.snippet : item.fullSnippet)" (click)="onSpanClick($event, file)"></span>
            <a *ngIf="!item.showMore" (click)="showMore(item)">less</a>
            <a *ngIf="item.showMore" (click)="showMore(item)">...</a>
          </div>
        </div>
      </div>
      <div class="icon-section" [ngStyle]="{'visibility':(file.id === selectedFile?.id || file.isLoading) ? 'visible' : 'hidden'}">
        <ng-container *ngIf="file.isLoading; else downloadIcon">
          <mat-spinner diameter="24"></mat-spinner>
        </ng-container>
        <ng-template #downloadIcon>
          <svg-icon key="download" *ngIf="shouldShowDownloadIcon(file)" size="sm" (click)="downloadFile(file)"></svg-icon>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #subscriptionView>
  <p class="body-1 subscription-message">{{ 'DOCS.SUBSCRIPTION_MESSAGE' | translate }}</p>
</ng-template>
