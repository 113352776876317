import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';

import { formatDate } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ActionMethodes } from '@ggp/generic/shared/services';
import { actionSubject, EntityType, GenericResponse } from '@ggp/generic/shared/util/models';

import { editUserDataMutationGQL } from '../schema/action-button-queries.schema';

@Injectable({
  providedIn: 'root',
})
export class EbpActionButtonService implements ActionMethodes {
  readonly actionAddedToProject = new BehaviorSubject<actionSubject>({ id: '', action: '' });
  readonly #apollo = inject(Apollo);

  favorite(ids: string[], type: EntityType) {
    return this.#apollo.mutate<GenericResponse>({
      mutation: editUserDataMutationGQL,
      variables: {
        userData: {
          [`${type}Ids`]: ids,
          favorite: true,
        },
      },
    });
  }

  unfavorite(ids: string[], type: EntityType) {
    return this.#apollo.mutate<GenericResponse>({
      mutation: editUserDataMutationGQL,
      variables: {
        userData: {
          [`${type}Ids`]: ids,
          favorite: false,
        },
      },
    });
  }

  read(projectIds: string[]) {
    return this.#apollo.mutate<GenericResponse>({
      mutation: editUserDataMutationGQL,
      variables: {
        userData: {
          projectIds: projectIds,
          read: true,
        },
      },
    });
  }

  unread(projectIds: string[]) {
    return this.#apollo.mutate<GenericResponse>({
      mutation: editUserDataMutationGQL,
      variables: {
        userData: {
          projectIds: projectIds,
          read: false,
        },
      },
    });
  }

  delete(projectIds: string[]) {
    return this.#apollo.mutate<GenericResponse>({
      mutation: editUserDataMutationGQL,
      variables: {
        userData: {
          projectIds: projectIds,
          deleted: true,
          deletedDate: formatDate(new Date(), 'YYYY-MM-dd', 'en-US'),
        },
      },
    });
  }

  export(): void {
    console.error('EBP-leads (Export) --> Method implemented.');
  }

  addLabel(): void {
    console.error('EBP-leads (Add label) --> Method implemented.');
  }

  restore(projectIds: string[]) {
    return this.#apollo.mutate<GenericResponse>({
      mutation: editUserDataMutationGQL,
      variables: {
        userData: {
          projectIds: projectIds,
          deleted: false,
          deletedDate: formatDate(new Date(), 'YYYY-MM-dd', 'en-US'),
        },
      },
    });
  }
}
