import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { withInMemoryScrolling } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DetailViewActions {
  private eventSubject = new BehaviorSubject<string | null>(null);
  private expansionSubject = new BehaviorSubject<string>('');

  projectIdSubject$ = new BehaviorSubject<string | null>(null);

  event$ = this.eventSubject.asObservable();
  expansion$ = this.expansionSubject.asObservable();

  moveToProject(v: string) {
    this.eventSubject.next(v);
  }

  emitExpansionEvent(v: string) {
    this.expansionSubject.next(v);
  }

  updateProjectId(id: string | null) {
    this.projectIdSubject$.next(id);
  }
}
