export const SocialMedia = [
  {
    key: 'linkedin',
    url: 'https://www.linkedin.com/company/aanbestedingskalender-nl',
  },
  {
    key: 'website',
    url: 'https://www.aanbestedingskalender.nl/',
  },
];
