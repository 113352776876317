export const PublicStages = [
  {
    id: 0,
    key: 'prior-information',
  },
  {
    id: 1,
    key: 'tender',
  },
  {
    id: 2,
    key: 'errata',
  },
  {
    id: 3,
    key: 'opening',
  },
  {
    id: 4,
    key: 'award',
  },
  {
    id: 5,
    key: 'expiry-alert',
  },
];

export const PublicStageEnum = {
  PRIOR: 'PRIOR',
  TENDER: 'TENDER',
  ERRATA: 'ERRATA',
  OPENING: 'OPENING',
  AWARD: 'AWARD',
  EXPIRYALERT: 'EXPIRYALERT',
};
