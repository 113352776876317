import { map, Observable, Subject, switchMap, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { PLATFORM_TOKEN } from '@ggp/generic/shared/config/token';
import { LargeDirective } from '@ggp/generic/shared/directives/element/size';
import { LocaleLanguageService, ProductsService, SubscriptionPage } from '@ggp/generic/shared/services';
import { Language } from '@ggp/generic/shared/util/models';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ggp-upgrade-subscription',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, LargeDirective, SvgIconComponent, MatDividerModule],
  templateUrl: './upgrade-subscription.component.html',
  styleUrls: ['./upgrade-subscription.component.scss'],
})
export class UpgradeSubscriptionComponent implements OnInit, OnDestroy {
  readonly #productsService = inject(ProductsService);
  readonly #plateform = inject(PLATFORM_TOKEN);
  readonly #translateService = inject(TranslateService);
  readonly #localeLanguageService = inject(LocaleLanguageService);
  readonly #onDestroy = new Subject();

  subscriptionContent$!: Observable<SubscriptionPage>;

  @Input() data!: any;

  ngOnInit(): void {
    this.getUpgradingContent();
    this.#translateService.onLangChange.pipe(takeUntil(this.#onDestroy)).subscribe((language: LangChangeEvent) => this.getUpgradingContent(language.lang));
    this.#productsService.setNotification(true);
  }

  getUpgradingContent(lng?: string) {
    const currentLng = lng ? Language[lng as keyof typeof Language] : this.#localeLanguageService.getLocaleLanguage();
    this.subscriptionContent$ = this.#productsService.getSubscriptionPage(this.#plateform.toLocaleLowerCase(), currentLng, this.data?.pageType);
  }

  getOptions(subscriptioncontent: any) {
    return subscriptioncontent.page_type === 'PRIVATE_PROJECT' ? subscriptioncontent.options_2 : subscriptioncontent.options;
  }

  ngOnDestroy(): void {
    this.#productsService.setNotification(false);
    this.#onDestroy.next(null);
    this.#onDestroy.complete();
  }
}
