<ggp-top-bar></ggp-top-bar>

<div class="body-container">
  <div class="left-bar">
    <ggp-left-bar></ggp-left-bar>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
