import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MultilangTextComponent } from '@ggp/generic/shared/components/multilang-text';
import { Brand, PLATFORM_TOKEN } from '@ggp/generic/shared/config/token';
import { LocalizedNumberPipe } from '@ggp/generic/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';

import { Accordion } from './accordion.interface';

@Component({
  selector: 'ggp-accordion',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, TranslateModule, LocalizedNumberPipe, MatDividerModule, MultilangTextComponent],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() menu!: Array<Accordion>;
  plateform = inject(PLATFORM_TOKEN);
  brand: typeof Brand = Brand;
}
