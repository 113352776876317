import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

export const RequiredAttribute = () => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  if (authenticationService.cognitoUser) return true;

  console.warn('required attributes must be filled within aws cognito');

  router.navigate(['/auth/login']);
  return false;
};
