import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Region } from '@ggp/generic/shared/util/models';

@Pipe({
  name: 'regions',
  standalone: true,
})
export class RegionsPipe implements PipeTransform {
  #translateService = inject(TranslateService);

  transform(regions: Region[]) {
    if (regions.length === 1) {
      return regions[0].value;
    }

    if (regions.filter(region => region.type === 'COUNTRY').length > 1) {
      return this.#translateService.instant('FILTER.MULTIPLE_COUNTRIES');
    }

    if (regions.filter(region => region.type === 'DEPARTEMENT').length === 1 || regions.filter(region => region.type === 'REGION').length === 1) {
      return regions.find(region => region.type === 'DEPARTEMENT')
        ? regions.find(region => region.type === 'DEPARTEMENT')?.value
        : regions.find(region => region.type === 'REGION')?.value;
    }

    const country = regions.filter(region => region.type === 'COUNTRY')[0]?.value || regions[0]?.countryCode;

    return `${this.#translateService.instant('FILTER.MULTIPLE_PROVINCES')} ${country}`;
  }
}
