import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ShareProject, ShareProjectResult } from '@ggp/generic/shared/util/models';

import { NotificationType } from './models';
import { AppNotification, NotificationMethods } from './models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements NotificationMethods {
  getAppNotifications(dateFrom?: string, dateTo?: string): Observable<Notification[]> {
    throw new Error('Method not implemented.');
  }
  getAppNotificationsCount(dateFrom?: string, dateTo?: string): Observable<number> {
    throw new Error('Method not implemented.');
  }
  markNotificationAsRead(notificationType: NotificationType | null, dateFrom?: string | null, dateTo?: string | null): Observable<any> {
    throw new Error('Method not implemented.');
  }
  shareProject(shareProject: ShareProject): Observable<ShareProjectResult> {
    throw new Error('Method not implemented.');
  }
  mapNotifications(notifications: Notification[]): AppNotification {
    throw new Error('Method not implemented.');
  }
}
