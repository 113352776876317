import { gql } from '@apollo/client';

export const getAllProjectsGQL = gql`
query getProjectsQuery($pagination: Pagination, $projectType: ProjectType!, $sort: Sort, $language: Language!, $filters: Filters) {
  getProjectsQuery(pagination: $pagination, projectType: $projectType, sort: $sort, language: $language, filters: $filters) {
    data {
      links {
        source
        link
      }
      isFavorite
      isRead
      id
      title
      companyNames
      creationDate
      startDate
      deadlineDate
      lastModificationDate
      hasKeywordsInDocuments
      hasDocuments
      projectType
      regions {
        value
        countryCode
        type
      }
      stages {
        order
        type
        isCurrent
      }
      tags{
            label
            color
        }
    }
    pagination {
      currentPage
      pageSize
      totalItems
      totalPages
    }
    keywords
  }
}
`;

export const getProjectStatusGQL = gql`
  query getProjectStatusQuery($projectId: String!) {
    getProjectStatusQuery(projectId: $projectId) {
      type
      isCurrent
      order
    }
  }
`;

export const getTotalItemsGQL = gql`
  query getTotalItemsQuery($projectType: ProjectType!, $filters: Filters) {
    getTotalItemsQuery(projectType: $projectType, filters: $filters)
  }
`;

export const getPublicProfilesGQL = gql`
  query getUserProfilesQuery($projectType: String!) {
    getUserProfilesQuery(projectType: $projectType){
        profileName
        profileId
        subscriptionProfileType
        notify
    }
  }
`;
