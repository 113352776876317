import { Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapComponent } from '@ggp/generic/shared/components/map';
import { ExecutionSite } from '@ggp/generic/shared/util/models';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

export enum ProjectType {
  PUBLIC_TENDER = 'PUBLIC_TENDER',
  PRIVATE_PROJECT = 'PRIVATE_PROJECT',
}

@Component({
  selector: 'ggp-map-view',
  standalone: true,
  imports: [CommonModule, MapComponent, SvgIconComponent, TranslateModule],
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit {
  @Input() locations!: Observable<ExecutionSite[]>;
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  projectType?: string;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.projectType = params['projectType'];
      this.changeDetectorRef.detectChanges();
    });
  }

  getLocation(location: ExecutionSite) {
    return `${location.region.label || ''} ${location.city.label || ''} ${location.country.label || ''}`;
  }

  hasCoordinates(locations: ExecutionSite[]) {
    return locations.some(elem => elem.location?.lat && elem.location?.lng);
  }
}
