import { Directive, ElementRef, inject, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ggpDataLoading]',
  standalone: true,
})
export class DataLoadingDirective implements OnChanges {
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  @Input() ggpDataLoading: boolean | null = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['ggpDataLoading']) {
      return;
    }

    if (changes['ggpDataLoading'].currentValue) {
      this.renderer.addClass(this.elementRef.nativeElement, 'loader-content');
    } else if (!changes['ggpDataLoading'].firstChange) {
      this.renderer.removeClass(this.elementRef.nativeElement, 'loader-content');
    }
  }
}
