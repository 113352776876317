<div *ngIf="subscriptionContent$ | async as subscriptionContent">
  <div class="header">
    <h4>{{subscriptionContent.title}}</h4>
    <p class="body-1" [innerHTML]="subscriptionContent.content"></p>
  </div>
  <div style="margin: 0 110px;">
    <div class="box">
      <div class="top-box">
        <div class="right">
          <h6>{{subscriptionContent.card_title}}</h6>
          <div class="single-mark" *ngFor="let benefit of subscriptionContent.options">
            <svg-icon key="include" size="sm"></svg-icon>
            <p class="body-1" [innerHTML]="benefit.description"></p>
          </div>
        </div>
        <div class="divider" *ngIf="subscriptionContent.page_type === 'PRIVATE_PROJECT'"></div>
        <div class="left" *ngIf="subscriptionContent.page_type === 'PRIVATE_PROJECT'">
          <h6>{{subscriptionContent.card_title_2}}</h6>
          <div class="single-mark" *ngFor="let benefit of subscriptionContent.options_2">
            <svg-icon key="include" size="sm"></svg-icon>
            <p class="body-1" [innerHTML]="benefit.description"></p>
          </div>
        </div>
      </div>
      <p class="body-1" [innerHTML]="subscriptionContent.card_content"></p>
    </div>
    <div class="contact-box">
      <a [href]="subscriptionContent.link" target="_blank">
        <button ggpLarge mat-flat-button data-cy="ggp-leads-detail-subscription">
          <span class="subtitle-2">{{subscriptionContent.submit_btn_title}}</span>
        </button>
      </a>
      <span class="body-2">{{subscriptionContent.card_note}}</span>
    </div>
  </div>
</div>
