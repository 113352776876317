import { gql } from '@apollo/client';

export const justAskGQL = gql`
  query justAskQuery($query: String!, $projectType: ProjectType!, $lang: String!) {
    justAskQuery(query: $query, projectType: $projectType, lang: $lang){
        status
        query
        narrative
        error_message
    }
  }
`;
