import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { CompanyServiceInterface } from '@ggp/generic/shared/services';
import { Organisation, OrganisationFilters, OrganisationSortCol, Sort } from '@ggp/generic/shared/util/models';
import { TranslateService } from '@ngx-translate/core';

import { CompanyQuery } from '../query/company-queries';
import { getOrganisationQueryGQL, getOrganisationsQueryGQL } from '../schema/company.schema';

@Injectable({
  providedIn: 'root',
})
export class EbpCompanyService implements CompanyServiceInterface {
  readonly #apollo = inject(Apollo);
  readonly #translateService = inject(TranslateService);

  get currentLang(): string {
    return this.#translateService.currentLang;
  }

  getCompanies(pageNumber: number, sort?: Sort<OrganisationSortCol>, filters?: OrganisationFilters): Observable<any> {
    return this.#apollo.query<CompanyQuery>({
      query: getOrganisationsQueryGQL,
      variables: {
        filters,
        pagination: {
          pageNumber,
        },
        sort,
        language: this.currentLang,
      },
      fetchPolicy: 'no-cache',
    });
  }

  getCompanyById(organisationId: string): Observable<any> {
    return this.#apollo
      .query<CompanyQuery>({
        query: getOrganisationQueryGQL,
        variables: {
          organisationId,
          language: this.currentLang,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map(res => ({ ...res.data.getOrganisationQuery, location: this.locationMapping(res.data.getOrganisationQuery) })));
  }

  locationMapping(organisation: Organisation) {
    return organisation
      ? {
          type: 'POSTCODE',
          city: {
            code: organisation.city,
            label: organisation.city,
          },
          address: organisation.address,
          country: {
            code: organisation.countryCode,
            label: organisation.countryCode,
          },
          role: 'EXECUTION_SITE',
          mapIcon: {
            color: 'var(--execution-point)',
            url: 'assets/icons/detail-view/building-types.svg',
            type: 'work',
            size: '16px',
          },
          location: null,
          postCode: `${organisation.postCode}-${organisation.city}`,
        }
      : {};
  }
}
