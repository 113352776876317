export const DEFAULT_PROFILE_DETAILS_ROUTES = {
  publicRoutes: [
    { path: 'overview', title: 'PROFILE.DETAILS.OVERVIEW.TITLE' },
    { path: 'location', title: 'PROFILE.DETAILS.LOCATION.TITLE' },
    { path: 'cpv', title: 'PROFILE.DETAILS.CPV.TITLE' },
    { path: 'activity', title: 'PROFILE.DETAILS.ACTIVITY.TITLE' },
    { path: 'class', title: 'PROFILE.DETAILS.CLASS.TITLE' },
    { path: 'scope', title: 'PROFILE.DETAILS.SCOPE.TITLE' },
    { path: 'source', title: 'PROFILE.DETAILS.SOURCE.TITLE' },
    { path: 'keyword', title: 'PROFILE.DETAILS.KEYWORD.TITLE' },
  ],
  privateRoutes: [
    { path: 'overview', title: 'PROFILE.DETAILS.OVERVIEW.TITLE' },
    { path: 'execution-site', title: 'PROFILE.DETAILS.LOCATION.EXECUTION_SITES' },
    { path: 'building-types', title: 'PROFILE.DETAILS.BUILDING_TYPE.TITLE' },
    { path: 'progress-status', title: 'PROFILE.DETAILS.PROGRESS_STATUS.TITLE' },
    { path: 'project-type', title: 'PROFILE.DETAILS.PROJECT_TYPE.TITLE' },
    { path: 'work-type', title: 'PROFILE.DETAILS.WORK_TYPE.TITLE' },
    { path: 'source', title: 'PROFILE.DETAILS.SOURCE.TITLE' },
    { path: 'keyword', title: 'PROFILE.DETAILS.KEYWORD.TITLE' },
  ],
};
