import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userFirstLetters',
  standalone: true,
})
export class UserFirstLettersPipe implements PipeTransform {
  transform(firstName: string, lastName: string): string {
    return firstName[0]?.concat(lastName[0]).toUpperCase();
  }
}
