import { Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectRenewalDates } from '@ggp/ebp/leads/util';
import { LocalizedDatePipe } from '@ggp/generic/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ggp-renewal-dates',
  standalone: true,
  imports: [CommonModule, TranslateModule, LocalizedDatePipe],
  templateUrl: './renewal-dates.component.html',
  styleUrls: ['./renewal-dates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewalDatesComponent {
  @Input() renewalDates!: Observable<ProjectRenewalDates>;
  displayDates(renewalDates: ProjectRenewalDates) {
    return renewalDates.startDate || renewalDates.firstPossibleEndDate || renewalDates.lastPossibleEndDate;
  }
}
