import { gql } from '@apollo/client';

export const getUserProfilesQueryGQL = gql`
  query getUserProfilesQuery($projectType: String!) {
    getUserProfilesQuery(projectType: $projectType){
      profileName
      profileId
      subscriptionProfileType
      notify
    }
  }
`;

export const deleteProfileMutationGQL = gql`
  mutation deleteProfileMutation($profileId: String!) {
    deleteProfileMutation(profileId: $profileId){
      profile_id
      is_deleted
  }}
`;

export const getProfileByIdQueryGQL = gql`
  query getProfileByIdQuery($profileId: String!, $language: Language!) {
    getProfileByIdQuery(profileId: $profileId, language: $language){
      id
      subscription_id
      description
      profile_value
      created_on
      is_deleted
      search_profile_type
      created_by
      notify
      subscription_profile_type
      profileNames
    }
  }
`;

export const saveProfileMutationGQL = gql`
  mutation saveProfileMutation($language: Language!, $saveSearch: SaveProfile!) {
    saveProfileMutation(language: $language, saveSearch: $saveSearch){
      created_by
      created_on
      description
  }}
`;
