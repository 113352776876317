import mongoose from 'mongoose';

const schema = new mongoose.Schema({
  userId: {
    type: String,
    required: true,
  },
  projectId: {
    type: String,
    required: true,
  },
  favorite: {
    type: Boolean,
    default: false,
  },
  read: {
    type: Boolean,
    default: false,
  },
  deleted: {
    type: Boolean,
    default: false,
  },
  deletedDate: {
    type: Date,
  },
  profileMatches: [
    {
      _id: false,
      profileId: {
        type: String,
        required: true,
      },
      documentId: {
        type: String,
        required: true,
      },
      documentType: {
        type: String,
        required: true,
      },
      createdDate: {
        type: Date,
        required: true,
        default: Date.now,
      },
      classification: {
        required: true,
        type: {
          _id: false,
          activities: {
            type: [String],
          },
          buildingPermitStates: {
            type: [String],
          },
          buildingTypes: {
            type: [String],
          },
          classes: {
            type: [String],
          },
          cpvs: {
            type: [String],
          },
          locations: {
            type: [String],
          },
          progressStatuses: {
            type: [String],
          },
          projectTypes: {
            type: [String],
          },
          scopes: {
            type: [
              {
                _id: false,
                scope: {
                  type: String,
                }
              }
            ],
          },
          source: {
            type: String,
          },
          workTypes: {
            type: [String],
          },
        },
      },
      keywords: {
        type: [String],
      },
    },
  ],
});

schema.index({ userId: 1, projectId: 1 }, { unique: true });

export const UserDataModel = mongoose.model('ProjectUserData', schema);
