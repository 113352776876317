/* eslint-disable @typescript-eslint/no-explicit-any */
export const gtag = (w: Window, d: Document, s: string, l: string, i: string) => {
  //typescript version of the script
  (w as any)[l] = (w as any)[l] || [];
  (w as any)[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s) as HTMLScriptElement;
  const dl = l !== 'dataLayer' ? '&l=' + l : '';

  j.async = true;
  j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;

  f.parentNode ? f.parentNode.insertBefore(j, f) : console.error('Parent node is null or undefined');
};
