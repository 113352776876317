import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class TextHighlightPipe implements PipeTransform {
  transform(text: string | string[], search: string | string[], extension?: string): string | string[] {
    if (Array.isArray(text)) {
      const result: string[] = [];
      text.map(item => {
        result.push(this.transformFunction(item, search));
      });
      return result;
    } else {
      const fileName = typeof search === 'string' ? text + '.' + extension : text;
      if (!search || search.length === 0) {
        return fileName;
      }
      return this.transformFunction(fileName, search);
    }
  }

  transformFunction(text: string, search: string | string[]) {
    if (typeof search === 'string') {
      const re = new RegExp(search, 'gi');
      return text.replace(re, '<span class="text-highlight">$&</span>');
    } else {
      return this.keywordsTransform(text, search);
    }
  }

  keywordsTransform(text: string, search: string[]): string {
    if (!text) return '';

    const sortedSearch = [...search].sort((a, b) => b.length - a.length);
    const escapedSearch = sortedSearch.map(term => term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
    const highlightRegex = new RegExp(`(${escapedSearch.join('|')})`, 'gi');

    return text.replace(highlightRegex, '<span class="highlight-keyword">$&</span>');
  }
}
