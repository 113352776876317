import { gql } from 'apollo-angular';

export const getNotificationsQueryGQL = gql`
  query getNotificationsQuery($notificationInput: NotificationInput!) {
    getNotificationsQuery(notificationInput: $notificationInput){
      notificationDate
      notificationType
      seen
      content {
        ... on AppNotificationNewProjectContent {
          title
          profileId
          count
        }
        ... on AppNotificationLeadShareContent {
          user {
            email
            firstName
            lastName
          },
          projectId
          projectTitle
          projectStage
          projectType
          url
        }
      }
    }
  }
`;

export const getNotificationsCountQueryGQL = gql`
  query getNotificationsCountQuery($notificationInput: NotificationInput!){
    getNotificationsCountQuery(notificationInput: $notificationInput){
      count
    }
  }
`;

export const markNotificationSeenMutationGQL = gql`
  mutation markNotificationSeenMutation($notificationInput: NotificationInput!) {
    markNotificationSeenMutation(notificationInput: $notificationInput)
  }
`;
