interface DefaultI18N {
  [key: string]: {
    default: string;
    languages: {
      [key: string]: {
        language: string;
        name: string;
        local: string;
        translatePlatform?: boolean;
      };
    };
  };
}

export const GGP_DEFAULT_I18N: DefaultI18N = {
  VPL: {
    default: 'fr',
    languages: {
      fr: {
        language: 'fr',
        name: 'français',
        local: 'fr-FR',
      },
      en: {
        language: 'en',
        name: 'english',
        local: 'en-US',
      },
    },
  },
  EBP: {
    default: 'nl',
    languages: {
      en: {
        language: 'en',
        name: 'english',
        local: 'en-US',
      },
      fr: {
        language: 'fr',
        name: 'français',
        local: 'fr-BE',
      },
      nl: {
        language: 'nl',
        name: 'nederlands',
        local: 'nl-BE',
      },
      de: {
        language: 'de',
        name: 'deutsch',
        local: 'de-BE',
      },
    },
  },
  ABK: {
    default: 'nl',
    languages: {
      nl: {
        language: 'nl',
        name: 'nederlands',
        local: 'nl-NL',
      },
      en: {
        language: 'en',
        name: 'english',
        local: 'en-US',
      },
      de: {
        language: 'de',
        name: 'deutsch',
        local: 'de-BE',
      },

      fr: {
        language: 'fr',
        name: 'français',
        local: 'fr-BE',
      },
    },
  },
  MPF: {
    default: 'fr',
    languages: {
      fr: {
        language: 'fr',
        name: 'français',
        local: 'fr-BE',
      },
      en: {
        language: 'en',
        name: 'english',
        local: 'en-US',
      },
      nl: {
        language: 'nl',
        name: 'nederlands',
        local: 'nl-BE',
        translatePlatform: false,
      },
      de: {
        language: 'de',
        name: 'deutsch',
        local: 'de-BE',
        translatePlatform: false,
      },
    },
  },
};
