import { gql } from '@apollo/client';

export const getSubscriptionTagsGQL = gql`
  query getSubscriptionTagsQuery {
    getSubscriptionTagsQuery {
      color
      label
    }
  }
`;

export const addTagsMutationGQL = gql`
  mutation addTagsMutation($subscriptionData: SubscriptionData!) {
    addTagsMutation(subscriptionData: $subscriptionData){
      statusCode
      message
  }}
`;

export const removeTagFromProjectMutationGQL = gql`
  mutation removeTagFromProjectMutation($subscriptionData: SubscriptionData!) {
    removeTagFromProjectMutation(subscriptionData: $subscriptionData){
      statusCode
      message
  }}
`;
