import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { GdprQuery } from './models/gdprQuery.model';
import { LessonQuery } from './models/lesson.model';
import { getGdprGql } from './schemas/gdpr.schema';
import { getLessonsGQL, getManualLinkGQL } from './schemas/help-section.schema';

@Injectable({
  providedIn: 'root',
})
export class HelpSectionService {
  private readonly apollo = inject(Apollo);

  getLessons(locale: string, scope: string) {
    return this.apollo
      .query<LessonQuery>({
        query: getLessonsGQL,
        variables: {
          locale,
          scope,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.getLessonsQuery;
        }),
      );
  }

  getGdpr(locale: string, scope: string) {
    return this.apollo
      .query<GdprQuery>({
        query: getGdprGql,
        variables: {
          locale,
          scope,
        },
      })
      .pipe(map(({ data }) => data.getGdprQuery));
  }

  getManualLink(locale: string, scope: string) {
    return this.apollo
      .query<LessonQuery>({
        query: getManualLinkGQL,
        variables: {
          locale,
          scope,
        },
      })
      .pipe(map(({ data }) => data.getManualLinkQuery));
  }
}
