import { gql } from '@apollo/client';

export const startExecutionGQL = gql`
  query startExecution($projectId: String!, $language: Language!) {
    startExecution(projectId: $projectId, language: $language)
  }
`;

export const onsaveResultGQL = gql`
  subscription onsaveResult {
    onsaveResult {
      status
      presignedUrl
    }
  }
`;

export const onGeneratePdfResultGQL = gql`
  subscription onGeneratePdfResult {
    onGeneratePdfResult
  }
`;
