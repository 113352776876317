import { model, Schema } from 'mongoose';

const schema = new Schema({
  subscriptionId: {
    type: String,
    required: true,
  },
  organisationId: {
    type: String,
    required: true,
  },
  tags: [
    {
      _id: false,
      color: {
        type: String,
      },
      label: {
        type: String,
      },
    },
  ],
});

schema.index({ subscriptionId: 1, organisationId: 1 }, { unique: true });

export const OrganisationSubscriptionData = model('OrganisationSubscriptionData', schema);
