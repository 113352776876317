import { gql } from '@apollo/client';

export const getOrganisationsQueryGQL = gql`
  query getOrganisationsQuery($filters: OrganisationFilters, $pagination: Pagination, $sort: Sort, $language: Language!) {
    getOrganisationsQuery(filters: $filters, pagination: $pagination, sort: $sort, language: $language){
      data {
        id
        name
        address
        postCode
        city
        isFavorite
        awardsCount
        projectsCount
        openingsCount
      }
      pagination {
        currentPage
        pageSize
        totalItems
        totalPages
      }
    }
  }`;

export const getOrganisationQueryGQL = gql`
  query getOrganisationQuery($organisationId: String!, $language: Language!) {
    getOrganisationQuery(organisationId: $organisationId, language: $language){
      id
      name
      address
      postCode
      countryCode
      city
      isFavorite
      awardsCount
      projectsCount
      openingsCount
      vat
      email
      phone
      website
    }
  }`;
