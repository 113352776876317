<button
  mat-button
  class="language-switcher"
  disableRipple
  [matMenuTriggerFor]="menu"
  aria-label="Language"
  data-cy="infoleads-language-selector"
  data-test-id="ggp-language-switcher"
>
  <mat-icon fontIcon="language"></mat-icon>
  <span>{{ currentLang }}</span>
</button>

<mat-menu #menu yPosition="below" class="language-menu">
  <ng-container *ngFor="let lang of supportedLanguages">
    <button
      type="button"
      mat-menu-item
      [value]="lang.language"
      (click)="onLanguageChange(lang.language)"
      [attr.data-test-id]="lang.language"
      [attr.data-cy]="lang.language"
    >
      <ng-container *ngIf="lang.language === currentLang; else regularOption">
        <p class="body-2">
          <strong>{{ lang.name | titlecase }}</strong>
        </p>
      </ng-container>

      <ng-template #regularOption>
        <p class="body-2">{{ lang.name | titlecase }}</p>
      </ng-template>
    </button>
  </ng-container>
</mat-menu>
