import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { SubscriptionServiceInterface } from '@ggp/generic/shared/services';

import { SubscriptionQuery } from '../query/subscriptionQuery';
import { getMyTeamGQL, getUsersGQL } from '../schema/subscription.schema';

@Injectable({
  providedIn: 'root',
})
export class EbpSubscriptionService implements SubscriptionServiceInterface {
  readonly #apollo = inject(Apollo);

  get showNotifications(): boolean {
    return true;
  }

  get showMyTeam(): boolean {
    return true;
  }

  get fetchUserDetails(): boolean {
    return true;
  }

  getSubscriptionUsers() {
    return this.#apollo
      .query<SubscriptionQuery>({
        query: getUsersGQL,
      })
      .pipe(
        map(({ data }) => {
          return data?.getUsersQuery;
        }),
      );
  }

  getMyTeam() {
    return this.#apollo
      .query<SubscriptionQuery>({
        query: getMyTeamGQL,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.getUsersQuery;
        }),
      );
  }
}
