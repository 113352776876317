<div class="container">
  <div class="header">
    <span class="subtitle-2">{{'PUBLIC.DETAIL.RECTIFICATIONS.MESSAGE' | translate}}</span>
    <ggp-language-tab [data]="languages$ | async" (selectedLang)="selectedLang($event)"></ggp-language-tab>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="getContent()">
    <div class="body-2" [innerHTML]="getContent()"></div>
  </ng-container>
</div>
