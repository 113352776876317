import { gql } from '@apollo/client';

export const getSubscriptionStatusQueryGQL = gql`
  query getSubscriptionStatusQuery($subscriptionId: Int!) {
    getSubscriptionStatusQuery(subscriptionId: $subscriptionId){
      id
      is_active
      invoice_status
    }
  }
`;
