import { catchError, EMPTY, filter, interval, Subject, switchMap, take, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { SmallDirective } from '@ggp/generic/shared/directives/element/size';
import { LoadingDirective } from '@ggp/generic/shared/directives/loading';
import { LocalizedDatePipe } from '@ggp/generic/shared/pipes';
import {
  DceDownloadResponse,
  DisplayPdf,
  DocsService,
  File,
  FileService,
  HighlighScroll,
  HighlightResult,
  ToasterService
} from '@ggp/generic/shared/services';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ggp-with-keywords',
  standalone: true,
  imports: [CommonModule, MatButtonModule, SmallDirective, SvgIconComponent, TranslateModule, LoadingDirective, MatExpansionModule, MatProgressSpinnerModule],
  providers: [LocalizedDatePipe],
  templateUrl: './with-keywords.component.html',
  styleUrls: ['./with-keywords.component.scss', '../shared-docs-styles.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WithKeywordsComponent implements OnInit, OnDestroy {
  readonly #docsService = inject(DocsService);
  readonly #toasterService = inject(ToasterService);
  readonly #translate = inject(TranslateService);
  readonly #onDestroy = new Subject();
  readonly #fileService = inject(FileService);
  readonly #cd = inject(ChangeDetectorRef);
  readonly #domSanitizer = inject(DomSanitizer);

  @Output() displayPdfEvent = new EventEmitter<DisplayPdf>();
  @Output() highlighitedPdfEvent = new EventEmitter<HighlighScroll>();

  @Input({ required: true }) docsWithKeyWords!: File[];
  @Input({ required: true }) documentId!: string;
  @Input({ required: true }) hasWithkeywordsProduct = signal<boolean | null>(null);
  @Input({ required: true }) searchKeywords!: string[];

  selectedFile?: File;
  downloadFilesIsLoading = false;

  ngOnInit(): void {
    this.docsWithKeyWords = this.docsWithKeyWords.map(element => {
      return {
        ...element,
        expanded: false,
      };
    });
    this.displayDefaultPdf();
  }

  displayPdf(event: MouseEvent, file: File) {
    event.preventDefault();
    this.emitPdf(file);
  }

  private displayDefaultPdf() {
    const file = this.docsWithKeyWords ? this.docsWithKeyWords[0] : null;
    if (file) {
      this.emitPdf(file);
    }
  }

  private emitPdf(file: File) {
    this.selectedFile = file;
    if (!file.extension) {
      const lastDotIndex = file.name.lastIndexOf('.');
      if (lastDotIndex !== -1) {
        file.extension = file.name.slice(lastDotIndex + 1);
      }
    }
    this.displayPdfEvent.emit({
      documentId: this.documentId,
      file: {
        ...file,
        name: this.removeExtension(file.name),
      },
    });
  }

  downloadFile(file: File): void {
    file.isLoading = true;
    interval(5000)
      .pipe(
        switchMap(() => this.#docsService.downloadFile(this.documentId, [file.id])),
        filter((response: any) => {
          if (!response) {
            throw new Error('Response is null');
          }
          return response?.progress === 1.0 && response?.status === 'done' && response?.url !== null;
        }),
        take(1),
        catchError(() => {
          file.isLoading = false;
          this.#toasterService.openToaster(this.#translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.#translate.instant('ERRORS.SERVER.TITLE'));
          this.#cd.detectChanges();
          return EMPTY;
        }),
        takeUntil(this.#onDestroy),
      )
      .subscribe(response => {
        file.isLoading = false;
        this.#fileService.download(response?.url, this.removeExtension(file.name));
        this.#cd.detectChanges();
      });
  }

  downloadSelectedFiles(): void {
    if (this.docsWithKeyWords) {
      this.downloadFilesIsLoading = true;

      const filesIds = this.docsWithKeyWords.map(f => f.id);

      interval(5000)
        .pipe(
          switchMap(() => this.#docsService.downloadFile(this.documentId, filesIds)),
          filter((response: DceDownloadResponse) => {
            if (!response) {
              throw new Error('Response is null');
            }
            return response?.progress === 1.0 && response?.status === 'done' && response?.url !== null;
          }),
          take(1),
          catchError(() => {
            this.downloadFilesIsLoading = false;
            this.#toasterService.openToaster(this.#translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.#translate.instant('ERRORS.SERVER.TITLE'));
            this.#cd.detectChanges();
            return EMPTY;
          }),
          takeUntil(this.#onDestroy),
        )
        .subscribe({
          next: response => {
            this.downloadFilesIsLoading = false;
            this.#fileService.download(response?.url);
            this.#cd.detectChanges();
          },
        });
    }
  }

  shouldShowDownloadIcon(file: File): boolean {
    return !this.downloadFilesIsLoading && (file.id === this.selectedFile?.id || !file.isLoading);
  }

  removeExtension(fileName: string) {
    return fileName.split('.').slice(0, -1).join('.');
  }

  showMore(result: HighlightResult) {
    result.showMore = !result.showMore;
  }

  onSpanClick(event: MouseEvent, file: File) {
    const target = event.target as HTMLElement;

    if (file.id !== this.selectedFile?.id) {
      this.emitPdf(file);
    }

    if (target.classList.contains('highlight-keyword')) {
      const spanText = target.innerText;
      if (spanText) {
        const index = +target.id.split(`-${spanText}-`)[1];
        const scrollResult: HighlighScroll = {
          highlightedSpan: spanText,
          index: index
        }
        this.highlighitedPdfEvent.next(scrollResult);
      }
    }
  }

  getContent(content: string) {
    return this.#domSanitizer.bypassSecurityTrustHtml(content);
  }

  ngOnDestroy(): void {
    this.#onDestroy.next(null);
    this.#onDestroy.complete();
  }
}
